// usage: log('inside coolFunc', this, arguments);
// paulirish.com/2009/log-a-lightweight-wrapper-for-consolelog/
window.log = function() {
	log.history = log.history || [];	// store logs to an array for reference
	log.history.push(arguments);
	arguments.callee = arguments.callee.caller;
	if (this.console) console.log(Array.prototype.slice.call(arguments));
};

// make it safe to use console.log always
(function(b) {function c() {}for(var d="assert,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profileEnd,time,timeEnd,trace,warn".split(","),a;a=d.pop();)b[a]=b[a]||c;})(window.console=window.console||{});

if (typeof LL == "undefined") {
	var LL = {};
}

LL.defaults = {
	"calendarIcon": "/resources/ver1-0/img/icon-calendar.png",
	"facebookAppId": "",
	"facebookLoginEnabled": true,
	"idLoginBox": "#loginbox_0",
	"idLoginForm": "#login_0",
	"idLoginLayer": "#login_head",
	"isLoggedIn": false,
	"isMobile": false,
	"textLogin": "Anmelden",
	"textLogout": "Abmelden",
	"urlLogin": "/community-webservice/service/login/",
	"urlLogout": "/community-webservice/service/logout/",
	"extLoginUrl": "/community-webservice/service/extloginuser/",
	"loginHtml": "",
	"loginErrors": ""
};

LL.actions = {
	"klick": function(ivw_context, ga_context) {
		if ((ivw_context !== "") && (typeof IOMm_data !== "undefined") && (typeof IOMm !== "undefined")) {
			try {
				IOMm("pageview", IOMm_data);
			} catch (e) {
				log("ERROR: IVW tracking (via IOMm) failed in LL.actions.klick()", e);
			}
		}

		if (ga_context !== "") {
			if (!ga_context && (ivw_context !== "")) {
				ga_context = ivw_context;
			}

			try {
				_gaq.push(['_trackPageview', ga_context]);
			} catch(e) {
				log("ERROR: Google tracking failed in LL.actions.klick()", e);
			}

			try {
				if (typeof dataLayer !== 'undefined') {
					dataLayer.push({
						'event': 'virtualPageViewTestcase',
						'virtualPageURL': ga_context,
					});
				}
			} catch(e) {
				log("ERROR: Google tracking failed in LL.actions.klick()", e);
			}
		}
	},

	// 7.2.5.2.5 // done
	"klick_videobox_thumbnail": function(args) {
		var title = args.title;
		LL.actions.klick("klick_videobox_thumbnail" + "_" + title);
	},
	"klick_videobox_pfeil_vor": function(args) {
		LL.actions.klick("klick_videobox_pfeil_vor");
	},
	"klick_videobox_pfeil_zurueck": function(args) {
		LL.actions.klick("klick_videobox_pfeil_zurueck");
	},
	"klick_videobox_lightbox_open": function(args) {
		var title = args.title;
		LL.actions.klick("klick_videobox_lightbox_open" + "_" + title, "");
	},
	"klick_videobox_lightbox_close": function(args) {
		//var title = args.title;
		//LL.actions.klick("klick_videobox_lightbox_close" + "_" + title);
	},

	// 7.2.7.2.5 // done
	"klick_f_a_box_pfeil_vor": function(args) {
		LL.actions.klick("klick_f_a_box_pfeil_vor");
	},
	"klick_f_a_box_pfeil_zurueck": function(args) {
		LL.actions.klick("klick_f_a_box_pfeil_zurueck");
	},

	// 7.2.9.2.5 // done
	"klick_navigation_anmelden_lightbox_open": function(args) {
		LL.actions.klick("", "klick_navigation_anmelden_lightbox_open");
	},
	"klick_anmelden_lightbox_ok_close": function(args) {
		//LL.actions.klick("klick_anmelden_lightbox_ok_close");
	},
	"klick_anmelden_lightbox_manuell_close": function(args) {
		//LL.actions.klick("klick_anmelden_lightbox_manuell_close");
	},
	"klick_anmelden_lightbox_senden_fehler": function(args) {
		LL.actions.klick("klick_anmelden_lightbox_senden_fehler");
	},

	// 7.2.15.2.5
	"klick_nutzer_ranking_reiter": function(args) {
		LL.actions.klick("klick_nutzer_ranking_reiter");
	},

	// 7.4.1.2.5 // done
	"klick_top_themen_pfeil_vor": function(args) {
		LL.actions.klick("klick_top_themen_pfeil_vor");
	},
	"klick_top_themen_pfeil_zurueck": function(args) {
		LL.actions.klick("klick_top_themen_pfeil_zurueck");
	},

	// 7.4.2.2.5 // done
	"klick_a_z_reiter": function(args) {
		var button_name = args.button_name;
		LL.actions.klick("klick_a_z_reiter" + "_" + button_name);
	},

	// 7.4.5.2.5 // done
	"klick_teaser_reiter": function(args) {
		var button_name = args.button_name;
		LL.actions.klick("klick_teaser_reiter" + "_" + button_name);
	},

	"klick_teaser_clip_paging": function(args) {
		var page = args.page;
		LL.actions.klick("klick_teaser_clip_paging" + "_" + page);
	},

	// 7.4.8.2.5
	"klick_f_a_box_reiter": function(args) {
		LL.actions.klick("klick_f_a_box_reiter");
	},

	// 7.4.9.2.5
	"klick_kommentarmodul_reiter": function(args) {
		LL.actions.klick("klick_kommentarmodul_reiter");
	},
	"klick_kommentarmodul_melden": function(args) {
		LL.actions.klick("klick_kommentarmodul_melden");
	},
	"klick_kommentarmodul_melden_lightbox_manuell_close": function(args) {
		//LL.actions.klick("klick_kommentarmodul_melden_lightbox_manuell_close");
	},
	"klick_kommentarmodul_melden_lightbox_ok_close": function(args) {
		//LL.actions.klick("klick_kommentarmodul_melden_lightbox_ok_close");
	},
	"klick_kommentarmodul_melden_lightbox_fehler": function(args) {
		LL.actions.klick("klick_kommentarmodul_melden_lightbox_fehler");
	},
	"klick_kommentarmodul_bewertung_hilfreich": function(args) {
		LL.actions.klick("klick_kommentarmodul_bewertung_hilfreich");
	},
	"klick_kommentarmodul_mehr": function(args) {
		LL.actions.klick("klick_kommentarmodul_mehr");
	},

	// 7.4.10.2.5
	"klick_fragestellen_anmelden_lightbox_open": function(args) {
		LL.actions.klick("klick_fragestellen_anmelden_lightbox_open");
	},
	"klick_fragestellen_anmelden_lightbox_ok_close": function(args) {
		//LL.actions.klick("klick_fragestellen_anmelden_lightbox_ok_close");
	},
	"klick_fragestellen_anmelden_lightbox_manuell_close": function(args) {
		//LL.actions.klick("klick_fragestellen_anmelden_lightbox_manuell_close");
	},
	"klick_fragestellen_anmelden_lightbox_senden_fehler": function(args) {
		LL.actions.klick("klick_fragestellen_anmelden_lightbox_senden_fehler");
	},

	// 7.5.2.2.5 // done
	"klick_karussell_pfeil_vor": function(args) {
		LL.actions.klick("klick_karussell_pfeil_vor");
	},
	"klick_karussell_pfeil_zurueck": function(args) {
		LL.actions.klick("klick_karussell_pfeil_zurueck");
	},
	"klick_karussell_button": function(args) {
		LL.actions.klick("klick_karussell_button");
	},

	// 7.6.2.2.5 // done
	"klick_inlinebild_lightbox_open": function(args) {
		var title = args.title;
		LL.actions.klick("klick_inlinebild_lightbox_open" + "_" + title);
	},
	"klick_inlinebild_lightbox_close": function(args) {
		//var title = args.title;
		//LL.actions.klick("klick_inlinebild_lightbox_close" + "_" + title);
	},

	// 7.6.3.2.5 // done
	"klick_fotostrecke_vor": function(args) {
		var headline = args.headline,
			trigger = args.trigger,
			gaContext = document.location.pathname + document.location.hash;
		LL.actions.klick("klick_fotostrecke_vor" + "_" + headline + "_" + trigger, gaContext);
	},
	"klick_fotostrecke_zurueck": function(args) {
		var headline = args.headline,
			trigger = args.trigger,
			gaContext = document.location.pathname + document.location.hash;
		LL.actions.klick("klick_fotostrecke_zurueck" + "_" + headline + "_" + trigger, gaContext);
	},

	"klick_expertise_vor": function(args) {
		var headline = args.headline,
			trigger = args.trigger,
			gaContext = document.location.pathname + document.location.hash;
		LL.actions.klick("klick_expertise_vor" + "_" + headline + "_" + trigger, gaContext);
	},
	"klick_expertise_zurueck": function(args) {
		var headline = args.headline,
			trigger = args.trigger,
			gaContext = document.location.pathname + document.location.hash;
		LL.actions.klick("klick_expertise_zurueck" + "_" + headline + "_" + trigger, gaContext);
	},

	// Selftest (GESUND-588)
	"klick_selftest": function(args) {
		LL.actions.klick("klick_selftest");
	}
};

LL.defaults.loginLayerOptions = {
	"cache": false,
	"cookiename": "communitycookie",
	"html": "",
	"idLoginBox": LL.defaults.idLoginBox,
	"idLoginForm": LL.defaults.idLoginForm,
	"idLoginLayer": LL.defaults.idLoginLayer,
	"idUsername": "#username",
	"idPassword": "#password",
	"idSaveLogin": "#saveLogin",
	"textLogin": LL.defaults.textLogin,
	"textLogout": LL.defaults.textLogout,
	"urlLayer" : "",
	"urlLogin": LL.defaults.urlLogin,
	"urlLogout": LL.defaults.urlLogout,
	"postLoginAction" : function () {},
	"redirectAfterLoginUrl": "",
	"redirectExtLoginNotFoundUrl": ""
};

LL.client = {
	"os": {
		"name": null,
		"version": null
	}
};


function detectClient() {
	var ua = navigator.userAgent;

	if (ua.indexOf("Android") >= 0) {
		LL.client.os.name = "Android";
	}
	if ((ua.indexOf("iPad") >= 0) || (ua.indexOf("iPod") >= 0) || (ua.indexOf("iPhone") >= 0)) {
		LL.client.os.name    = "iOS";
		var osName           = "OS ";
		ua                   = ua.replace( '_', '.' );
		LL.client.os.version = parseFloat(ua.slice(ua.indexOf(osName) + osName.length));
	}

	if (LL.client.os.name && !LL.client.os.version) {
		var osName = LL.client.os.name;
		LL.client.os.version = parseFloat(ua.slice(ua.indexOf(osName) + osName.length + 1));
	}
}

// cookie handling
function createCookie(name, value, days) {
	if (days) {
		createCookieSeconds(name, value, days*24*60*60);
	} else {
		createCookieSeconds(name, value);
	}
}

function createCookieSeconds(name, value, seconds) {
	if (seconds) {
		var date = new Date();
		date.setTime(date.getTime()+(seconds*1000));
		var expires = "; expires="+date.toGMTString();
	} else {
		var expires = "";
	}
	document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name,"",-1);
}

// fw functions
function fwAjax(options) {
	var opts = $.extend({
		context: document.body,
		data: null,
		error: null,
		success: null,
		target: null,
		type: "POST",
		url: ""
	}, options);

	var data = opts.data;
	if (!data && (opts.type == "POST")) {
		data = "nocache="+(Math.random()*100000);
	}

	$.ajax({
		"cache": false,
		"context": opts.context,
		"data": data,
		"type": opts.type,
		"url": opts.url
	})
	.success(function(data, textStatus, jqXHR) {
		if (opts.target) {
			$(opts.target).html(data);
		}
		if (typeof opts.success == "function") {
			opts.success.call();
		}
	})
	.error(function(jqXHR, textStatus, errorThrown) {
		log(jqXHR, textStatus, errorThrown);
		if (typeof opts.error == "function") {
			opts.error.call();
		}
	});
}

function fwUpdateURLParameter(url, key, value) {
	var re = new RegExp("([?|&])" + key + "=.*?(&|$)", "i");
	separator = url.indexOf('?') !== -1 ? "&" : "?";
	if (url.match(re)) {
		return url.replace(re, '$1' + key + "=" + value + '$2');
	} else {
		return url + separator + key + "=" + value;
	}
}

// GeoLocation
function logGeoLocationError(error) {
	switch (error.code) {
		case error.PERMISSION_DENIED:
			log("User denied the request for Geolocation.");
			break;
		case error.POSITION_UNAVAILABLE:
			log("Location information is unavailable.");
			break;
		case error.TIMEOUT:
			log("The request to get user location timed out.");
			break;
		case error.UNKNOWN_ERROR:
			log("An unknown error occurred.");
			break;
	}
}

// poll handling
function ll_pollGoBack(widgetDiv) {
	$('#'+widgetDiv+'_r').hide();
	$('#'+widgetDiv).show();

	LL.actions.klick("klick_voting_goback");

	return false;
}

function ll_pollResults(widgetDiv) {
	var url = $("#"+widgetDiv+'_rb').attr("href");
	$.ajax({
		"cache": false,
		"data": "nocache="+(Math.random()*100000),
		"type": "POST",
		"url": url
	})
	.success(function(data, textStatus, jqXHR) {
		$("#"+widgetDiv).hide();
		$("#"+widgetDiv+'_r')
			.html(data)
			.show();
		LL.actions.klick("klick_voting_results");
	})
	.error(function(jqXHR, textStatus, errorThrown) {
		log(jqXHR, textStatus, errorThrown);
	});

	return false;
}

function reloadAds() {
	try {
		if (typeof Spark !== "undefined") {
			log("Reloading Spark ads ...");

			Spark.cmd.push(function() {
				Spark.definitionService.refresh();
			});
		}

		if (typeof ASCDP !== "undefined") {
			log("Reloading ASMI ads ...");

			ASCDP.adS.reloadAds();
		}

		if (typeof adition !== "undefined") {
			log("Reloading Adition ads ...");
	
			adition.srq.push(function(api) {
				api.load().completeRendering();
			});
		}
	} catch (e) {
		log("Error: Failed reloading ads.", e);
	}
}

function setDatepickerLanguage() {
	$.datepicker.regional['de'] = {
		changeMonth: true,
		changeYear: true,
		closeText: 'schließen',
		prevText: '&#x3c;zurück',
		nextText: 'Vor&#x3e;',
		currentText: 'heute',
		monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
		monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
		dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
		dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
		dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
		weekHeader: 'Wo',
		dateFormat: 'dd.mm.yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		showOtherMonths: false,
		selectOtherMonths: false,
		yearSuffix: ''
	};
	$.datepicker.setDefaults($.datepicker.regional['de']);
}

(function($) {
	// global object with settings
	var ll = {
		// ie6 test
		"isIE": (function() { return /MSIE/i.test(navigator.userAgent); })(),
		// get url params as object
		"params": (function() {
			var params = {};
			var rawParams = window.location.search;
			if (rawParams === "") {
				return params;
			}
			else {
				rawParams = rawParams.substring(rawParams.indexOf("?")+1).split("&");
				for(var idx in rawParams) {
					var param = rawParams[idx].split("=");
					param[1] === "true" ? param[1] = true : param[1] = false;
					params[param[0]] = param[1];
				}
				return params;
			}
		})(),
		getLightboxLayerTop: function (layerInnerHeight) {
			var viewportHeight = $(window).height();
			var viewportOffset = $(window).scrollTop();
			var isTouchDevice = $("html").hasClass("touch");

			if (!isTouchDevice) {
				return (viewportOffset + (viewportHeight/2 - layerInnerHeight/2));
			} else {
				return (viewportOffset + 10);
			}
		}
	};

	(function() {
		if (ll.params.dev) {
			$(".dev, .dev_q").show();
		}
	})();


	// Helper function to get the right index for galleries.
	var getIndex = function( idx, length ) {
		if ( idx >= length ) {
			return idx % length;
		}
		else if ( idx < 0 ) {
			return getIndex( length + (idx % length) );
		}
		else {
			return idx;
		}
	};


	$.fn.reverse = [].reverse;


	/**
	 * Lädt den Inhalt des angebundenen Elements von der im Parameter <code>url</code> übergebenen
	 * AJAX-URL in periodischem Abstand immer wieder.
	 * 
	 * @param initial
	 *            Definiert, ob das erste Laden bereits beim Aufruf passieren soll (default: true).
	 * @param interval
	 *            Definiert das Intervall in Millisekunden.
	 * @param refresh
	 *            Definiert, ob das Laden periodisch wiederholt werden soll (default: true).
	 */
	$.fn.fwAjaxRefresh = function(options) {
		return this.each(function() {
			var opts = $.extend({
				before: null,
				context: document.body,
				data: null,
				error: null,
				initial: true,
				interval: null,
				refresh: true,
				success: null,
				type: "GET",
				url: null
			}, options);

			var that = this;

			if (opts.initial) {
				$(that).fwAjaxUpdate(opts);
				opts.initial = false;
			}

			if (opts.refresh) {
				setTimeout(function () {
					$(that).fwAjaxUpdate(opts).fwAjaxRefresh(opts);
				}, opts.interval);
			}
		});
	}

	/**
	 * Lädt den Inhalt des Elements <code>target</code>, wenn der Wert der angebundenen Selectbox
	 * geändert wird. Die AJAX-URLs müssen im <code>value</code> der <code>option</code>s stehen.
	 */
	$.fn.fwAjaxSelect = function(options) {
		return this.each(function() {
			var opts = $.extend({
				before: null,
				context: document.body,
				data: null,
				error: null,
				success: null,
				target: null,
				type: "GET"
			}, options);

			$(this).change(function() {
				var url = $(this).val();
				$(opts.target).fwAjaxUpdate(opts);
				return false;
			});
		});
	};

	/**
	 * Lädt den Inhalt des angebundenen Elements von der im Parameter <code>url</code> übergebenen
	 * AJAX-URL.
	 * 
	 * Beispiel: $("#elementId").fwAjaxUpdate({"url": "..."});
	 */
	$.fn.fwAjaxUpdate = function(options) {
		return this.each(function() {
			var opts = $.extend({
				before: null,
				context: document.body,
				data: null,
				error: null,
				success: null,
				type: "GET",
				url: null
			}, options);

			var that = this;
			var data = opts.data;
			if (!data && (opts.type == "POST")) {
				data = "nocache="+(Math.random()*100000);
			}

			if (typeof opts.before == "function") {
				data = opts.before.call(this, data);
			}

			$.ajax({
				"cache": false,
				"context": opts.context,
				"data": data,
				"error": function(jqXHR, textStatus, errorThrown) {
					log(jqXHR, textStatus, errorThrown);
					if (typeof opts.error == "function") {
						opts.error.call();
					}
				},
				"success": function(data, textStatus, jqXHR) {
					$(that).html(data);
					if (typeof opts.success == "function") {
						opts.success.call();
					}
				},
				"type": opts.type,
				"url": opts.url
			});
		});
	};


	$.fn.llAsk = function( opts ) {
		var options = $.extend({
			urlLayer: "ajax/_ask.html"
		}, opts);

		return this.each(function() {

			$(this)
				.click(function() {
					$("#layer").llLayer({
						"callback": function() {
						},
						"closeCallback": function() {
							LL.actions.klick_anmelden_lightbox_manuell_close();
						},
						"url": options.urlLayer
					});
					return false;
				});
		});
	};


	$.fn.llCaroussel = function(opts) {
		var options = $.extend({
			"duration": 1000
		}, opts);

		return this.each(function() {
			// jquery objects
			var $paging = $(".paging", this);
			var $previous = $(".previous", this);
			var $next = $(".next", this);
			var $pagingButtons = $(".paging .btn", this);
			var $slider = $(".slider", this);

			// other vars
			var index = 0;
			var groupWidth = $(".group", this).outerWidth(true);
			var groupSize = $(".group", this).size();
			var sliderWidth = groupWidth * groupSize;
			var sliderIsAnimated = false;

			$slider.width(sliderWidth);

			var slide = function(idx, callback) {
				// set active state
				$pagingButtons
					.removeClass("active")
					.eq(getIndex(idx, groupSize))
					.addClass("active");

				// animate
				sliderIsAnimated = true;
				$slider
					.animate({
						"left": -(idx * groupWidth)
					},
					options.duration,
					function() {
						if (typeof callback === "function") {
							callback.call();
						}
						sliderIsAnimated = false;
					});
			};


			$pagingButtons.click(function(e) {
				e.preventDefault();
				if (sliderIsAnimated) { return false; }
				var idx = $pagingButtons.index(this);
				slide(idx);
				index = getIndex(idx, groupSize);

				LL.actions.klick_karussell_button();
			});

			$previous.click(function(e) {
				e.preventDefault();
				if (sliderIsAnimated) { return false; }
				var idx = index-1;

				if (idx < 0) {
					var offsetLeft = $slider.position().left;
					var $originals = $slider.find(".group");

					$slider
						.width(sliderWidth*2)
						.prepend($slider.html())
						.css({
							"left": -sliderWidth
						});

					slide(getIndex(idx, groupSize), function() {
						$originals.remove();
						$slider.width(sliderWidth);
						index = getIndex(idx, groupSize);
					});
				}
				else {
					slide(idx);
					index = getIndex(idx, groupSize);
				}

				LL.actions.klick_karussell_pfeil_zurueck();
			});

			$next.click(function(e) {
				e.preventDefault();
				if (sliderIsAnimated) { return false; }
				var idx = index+1;

				if (idx === groupSize) {
					var $originals = $slider.find(".group");

					$slider
						.width(sliderWidth*2)
						.append($slider.html());

					slide(idx, function() {
						$originals.remove();
						$slider
							.css({
								"left": 0
							})
							.width(sliderWidth);
						index = getIndex(idx, groupSize);
					});
				}
				else {
					slide(idx);
					index = getIndex(idx, groupSize);
				}

				LL.actions.klick_karussell_pfeil_vor();
			});


			// show paging
			$paging.show();
		});
	};


	$.fn.llCommentFormToggle = function() {
		return this.each(function() {

			$(".more", this).toggle(
					function() {
						$(this)
							.text("Kommentareingabe schließen")
							.addClass("open")
							.next("form")
							.slideDown();
					},
					function() {
						$(this)
							.text("Antwort kommentieren")
							.removeClass("open")
							.next("form")
							.slideUp();
					});

		});
	};


	$.fn.llDailyDosis = function(opts) {
		var options = $.extend({
			"duration": 1000
		}, opts);

		return this.each(function() {

			var $dd = $("#daily_dosis", this);
			var $switch = $("#dd_switch", this);

			$switch.click(function(e) {
				e.preventDefault();

				if ($dd.is(":visible")) {
					$switch
						.addClass("not_expanded")
						.removeClass("expanded");
					$dd.slideUp(options.duration);
				}
				else {
					$switch
						.addClass("expanded")
						.removeClass("not_expanded");
					$dd.slideDown(options.duration);
				}
			});

		});
	};


	$.fn.llDatepicker = function(options) {
		return this.each(function() {
			// Initialize when DOM is ready. jQueryUI + Datepicker are loaded at the end of the document
			$(function () {
				var opts = $.extend({
					"showOn": "button",
					"buttonImage": LL.defaults.calendarIcon,
					"buttonImageOnly": true,
					"numberOfMonths": [1, 1],
					"showOptions": { "direction": "down" },
					"dateFormat": "dd.mm.yy",
					"stepMonths": 1,
					"option": $.datepicker.regional['de']
				}, options);

				$(this).datepicker(opts);
			});
		});
	};


	$.fn.llDoxter = function() {
		return this.each(function() {
			var $links = $("a", this);

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function(position) {
					var lat = position.coords.latitude;
					var lon = position.coords.longitude;

					$links.each(function() {
						var $link          = $(this);
						var url            = $link.attr("href");
						var locationExists = (url.indexOf("&lng=") > 0);

						if (!locationExists) {
							url += (url.indexOf("?") == -1) ? "?" : "&";
							url += "lat=" + lat + "&lng=" + lon;

							$link.attr("href", url);
						}
					});
				}, logGeoLocationError);
			}
		});
	};


	$.fn.llForm = function(options) {

		return this.each(function() {

			var opts = $.extend({
				before : null,
				context : document.body,
				error : null,
				success : null,
				type : "POST",
				url : ""
			}, options);

			var that = this;
			var $that = $(this);
			var $form = $("form", this);

			var $submitLink = $("a.submit", this);

			$submitLink.live("click", function() {
				$parentForm = $(this).closest("form");
				$parentForm.trigger("submit");
				return false;
			});

			$submitLink = $("a.submitAction", this);

			$submitLink.live("click", function() {
				$parentForm = $(this).closest("form");
				$parentForm.trigger("submit");
				return false;
			});
			
			$("input[type=submit]", this).live("click", function() {
				$("input[name=action]", that).val($(this).attr("name"));
			});

			$form.live("submit", function() {
				var data = $(this).serializeArray();

				if (typeof opts.before == "function") {
					data = opts.before.call(this, data);
				}
				
				$.ajax({
					"cache": false,
					"context" : opts.context,
					"data" : data,
					"type" : opts.type,
					"url" : opts.url
				}).success(function(data, textStatus, jqXHR) {
					$that.html(data);
					if (typeof opts.success == "function") {
						opts.success.call();
					}
				}).error(function(jqXHR, textStatus, errorThrown) {
					log(jqXHR, textStatus, errorThrown);
					if (typeof opts.error == "function") {
						opts.error.call();
					}
				});

				return false;
			});
		});
	};


	$.fn.llJsonForm = function( options ) {

		return this.each(function() {

			var opts = $.extend({
				before: null,
				context: document.body,
				error: null,
				success: null,
				type: "POST",
				url: ""
			}, options);
			if (
				(
					opts.success === null ||
					opts.error === null
				) &&
				(
					typeof opts.success !== "function" ||
					typeof opts.error !== "function"
				)
			) {
				throw "llJsonForm instantiation error: a success and a error handler have to be included in the options!";
			}

			var $that = $(this);
			var $form = $("form", this);

			var $submitLink = $(".submit", this);

			$submitLink
				.click(function() {
					$form.trigger("submit");
					return false;
				});

			$form
				.live("submit", function() {
					var dataString = $(this).serialize();

					if (typeof opts.before === "function") {
						dataString = opts.before.call(this, dataString);
					}

					$.ajax({
						"cache": false,
						"context": opts.context,
						"data": dataString,
						"type": opts.type,
						"url": opts.url
					})
					.success(function(data, textStatus, jqXHR) {
						opts.success.apply(this, arguments);
					})
					.error(function(jqXHR, textStatus, errorThrown) {
						log(jqXHR, textStatus, errorThrown);
						opts.error.apply(this, arguments);
					});

					return false;
				});
		});
	};


	$.fn.llImgZoom = function(opts) {

		return this.each(function() {
			var options = $.extend({
				"html": ""
			}, opts);

			var $imgLink = $(".img a", this);
			var alt = $(".img img", this).attr("alt");

			// append magnifier glas
			$imgLink
				.append("<span class=\"magnifier\" title=\"Vergrößern\" />")
				.click("click", function(e) {
					e.preventDefault();
					LL.actions.klick_inlinebild_lightbox_open({
						"title": alt
					});

					$("#layer").llLayerInject({
						"closeCallback": function() {
							LL.actions.klick_inlinebild_lightbox_close({
								"title": alt
							});
						},
						"html": options.html
					});
					return false;
				});
		});
	};


	$.fn.llGalleryEdit = function() {
		return this.each(function() {
			var $input       = $(".images .image input", this);
			var $selectCount = $(".selectCountValue", this);
			var that         = this;

			$input.click(function() {
				var count = 0;

				$input.each(function() {
					if ($(this).is(':checked')) {
						count++;
					}
				});

				$selectCount.html(count);
			});
		});
	};


	$.fn.llGalleryView = function(options) {
		return this.each(function() {
			var opts = $.extend({
				"galleryClass": "gallery"
			}, options);

			var $links = $(".view", this);
			var that   = this;

			$links.click(function(e) {
				e.preventDefault();

				// Da der Button kein href hat, den Link im selben "gallery"-div ermitteln:
				var $gallery = $(this).closest("." + opts.galleryClass);
				var url      = $("a.view", $gallery).attr("href");

				$("#layer").llLayer({
					"closeCallback": function() {
						$("#layer").html("");
					},
					"duration": 0,
					"method": "POST",
					"url": url
				});
				return false;
			});
		});
	};


	$.fn.llLayerInject = function(opts) {

		return this.each(function() {
			var options = $.extend({
				"callback": null,
				"closeCallback": null, // will be called after the layer is closed
				"closeOnClick": true,
				"duration": 500, // fadeIn/fadeOut duration
				"html": null
			}, opts);

			var $layer = $(this);

			var closeLayer = function() {
				$layer.fadeOut(options.duration);
				if (typeof options.closeCallback == "function") {
					options.closeCallback.call();
				}
			};

			// fade in, resize layer. add closing
			var init = function() {
				resizeLayer();
				$layer
					//.fadeIn(options.duration)
					.show();
				if (options.closeOnClick) {
					$layer
						.addClass("clickable")
						.unbind("click")
						.click(function(e) {
							if (e.target===this) {
								$(window).unbind("resize");
								closeLayer();
							}
						});
				}
			};

			// var position the layer
			var positionInnerLayer = function() {

				$layer.css({
					"display": "block",
					"visibility": "hidden"
				});

				var $layerInner = $(".layer_inner", $layer);

				var offsetTop = ll.getLightboxLayerTop($layerInner.outerHeight(false));

				// set width if .img
				if ($layer.find(".layer_img").size()) {
					var width = $layer.find(".img img").width();
					$layerInner
						.width(width);
				}

				$layer.css({
					"visibility": "visible"
				});

				$layerInner
					.css({
						"margin-top": offsetTop,
						"visibility": "visible"
					});
			};

			// add resizing functionality
			var resizeLayer = function() {
				var layerHeight = $("body").height() < $(window).height() ? $(window).height() : $("body").height();
				var layerWidth = $(window).width();

				$layer
					.css({
						"height": layerHeight,
						"width": layerWidth
					});
			};

			// Nicht bei Touch-Devices, da es Probleme macht, wenn die Tastatur eingeblendet wird!
			var isTouchDevice = $("html").hasClass("touch");
			if (!isTouchDevice) {
				$(window).resize(function() {
					resizeLayer();
					positionInnerLayer();
				});
			}

			// inject html and position
			$layer
				.html(options.html)
				.find(".close")
				.click(function() {
					$(window).unbind("resize");
					closeLayer();
				});

			$(".layer_inner", $layer).show();
			positionInnerLayer();

			if (typeof options.callback === "function") {
				options.callback.call();
			}

			// call init if the layer is hidden
			//if ($layer.is(":hidden")) {
				init();
			//}

		});
	};


	$.fn.llLayer = function(opts) {
		return this.each(function() {
			var options = $.extend({
				"callback": null,
				"closeCallback": null, // will be called after the layer is closed
				"closeOnClick": true,
				"data": null,
				"duration": 1000, // fadeIn/fadeOut duration
				"focusId": null,
				"method": "GET",
				"responseType": "html",
				"url": null
			}, opts);

			var $layer = $(this);
			var data = options.data;
			if (!data && (options.method == "POST")) {
				data = "nocache="+(Math.random()*100000);
			}

			var closeLayer = function() {
				$layer.fadeOut(options.duration);
				if (typeof options.closeCallback == "function") {
					options.closeCallback.call();
				}
			};

			// fade in, resize layer. add closing
			var init = function() {
				resizeLayer();
				$layer
					//.fadeIn(options.duration)
					.show();
				if (options.closeOnClick) {
					$layer
						.addClass("clickable")
						.unbind("click")
						.click(function(e) {
							if (e.target===this) {
								closeLayer();
							}
						});
				}
			};

			// var position the layer
			var positionInnerLayer = function() {
				var $layerInner = $(".layer_inner", $layer);

				var offsetTop = ll.getLightboxLayerTop($layerInner.outerHeight(false));

				// set width if .img
				if ($layer.find(".layer_img").size()) {
					var width = $layer.find(".img img").width();
					$layerInner
						.width(width);
				}

				$layerInner
					.css({
						"margin-top": offsetTop,
						"visibility": "visible"
					});
			};

			// add resizing functionality
			var resizeLayer = function() {
				var layerHeight = $("body").height() < $(window).height() ? $(window).height() : $("body").height();
				var layerWidth = $(window).width();

				$layer
					.css({
						"height": layerHeight,
						"width": layerWidth
					});
			};

			// Nicht bei Touch-Devices, da es Probleme macht, wenn die Tastatur eingeblendet wird!
			var isTouchDevice = $("html").hasClass("touch");
			if (!isTouchDevice) {
				$(window).resize(function() {
					resizeLayer();
					positionInnerLayer();
				});
			}

			// load ajax and position
			$.ajax({
				"cache": false,
				"dataType": options.responseType,
				"type": options.method,
				"url": options.url,
				"data": data
			})
				.success(function(response) {
					// strip body
					$layer
						.html(response)
						.find(".close")
						.unbind("click")
						.click(function() {
							closeLayer();
						});

					positionInnerLayer();

					if (options.focusId) {
						var elem = document.getElementById(options.focusId);
						if (elem) {
							elem.focus();
						}
					}

					if (typeof options.callback === "function") {
						options.callback.call();
					}
				});

			// call init if the layer is hidden
			if ($layer.is(":hidden")) {
				init();
			}
		});
	};


	$.fn.llLink = function( options ) {

		return this.each(function() {

			var opts = $.extend({
				before: null,
				context: document.body,
				data: null,
				error: null,
				klick_function: null,
				klick_function_use_title_as_button_name: false,
				success: null,
				target: null,
				type: "POST",
				url: $(this).data("ajax-url") || $(this).attr("href")
			}, options);

			var that = this;

			$(this)
				.click(function() {
					var data = opts.data;
					if (!data && (opts.type == "POST")) {
						data = "nocache="+(Math.random()*100000);
					}

					if (typeof opts.before == "function") {
						data = opts.before.call(this, data);
					}

					$.ajax({
						"cache": false,
						"context": opts.context,
						"data": data,
						"type": opts.type,
						"url": opts.url
					})
					.success(function(data, textStatus, jqXHR) {
						$(opts.target).html(data);
						if ( typeof opts.success == "function" ) {
							opts.success.call();
						}
						if (typeof opts.klick_function == "function") {
							if (opts.klick_function_use_title_as_button_name) {
								opts.klick_function( { button_name: $(that).attr('title') } );
							} else {
								opts.klick_function( { button_name: $(that).text() } );
							}
						}
					})
					.error(function(jqXHR, textStatus, errorThrown) {
						log(jqXHR, textStatus, errorThrown);
						if ( typeof opts.error == "function" ) {
							opts.error.call();
						}
					});

					return false;
				});
		});
	};


	$.fn.llLoadTab = function(opts) {
		var options = $.extend({
			url : null
		}, opts);
		
		var that = this;
	
		$.ajax({
			"type": "GET",
			"url": options.url
		})
		.success(function(data, textStatus, jqXHR) {
			$(that).html(data);
		})
		.error(function(jqXHR, textStatus, errorThrown) {
			log("error", jqXHR, textStatus, errorThrown);
		});
	};


	$.fn.llLoggedOutOnly = function() {
		var cookie = readCookie("communitycookie");

		if (cookie !== null) { // logged in
			$(this).hide();
		}
	};


	$.fn.llLogin = function(opts) {
		return this.each(
			function () {
				var that = this;
				var options = $.extend({
					"cache": false,
					"cookiename": "communitycookie",
					"idLoginBox": LL.defaults.idLoginBox,
					"idLoginForm": LL.defaults.idLoginForm,
					"idLoginLayer": LL.defaults.idLoginLayer,
					"idUsername": LL.defaults.idLoginForm + "_0",
					"idPassword": LL.defaults.idLoginForm + "_1",
					"idSaveLogin": LL.defaults.idLoginForm + "_saveLogin",
					"textLogin": LL.defaults.textLogin,
					"textLogout": LL.defaults.textLogout,
					"urlLayer" : "",
					"urlLogin": LL.defaults.urlLogin,
					"urlLogout": LL.defaults.urlLogout,
					"postLoginAction" : function () {},
					"redirectAfterLoginUrl": LL.defaults.loginLayerOptions.redirectAfterLoginUrl
				}, opts);

				var $errorMessage = $(".error_message", this);
				var $loginBox = $("#" + options.loginBox);

				// check for cookie
				var cookie = readCookie(options.cookiename);

				if (cookie === null) {
					// not logged in
					$loginBox.slideDown();
				} else {
					// logged in
					$loginBox.slideUp();
					return;
				}

				$(this)
					.unbind("submit")
					.submit(function () {
						var form = this;
						var submit = true;
						var data = $(this).serializeArray();
						var cookieTimeout = 10 * 365 * 86400;	// 10 Jahre in Sekunden
	
						if ($(options.idSaveLogin, form).is(':checked')) {
							data.push({ "name": "cookieTimeout", "value": (cookieTimeout) });
						}
	
						$("input", this).removeClass("error");
						$errorMessage.html('');
	
						if ($(options.idUsername, this).val() === "") {
							$(options.idUsername, this).addClass("error");
							submit = false;
							$errorMessage.append('Benutzername / E-Mail darf nicht leer sein!');
						}
						if ($(options.idPassword, this).val() === "") {
							$(options.idPassword, this).addClass("error");
							submit = false;
							$errorMessage.append('<br/>Das Passwort darf nicht leer sein!');
						}
	
						if (submit) {
							$.ajax({
								"cache": options.cache,
								"data": data,
								"type": "POST",
								"url": options.urlLogin
							})
							.success(
								function (data, textStatus, jqXHR) {
									cookie = readCookie(options.cookiename);
	
									$errorMessage.slideUp();
	
									if (cookie === null) {
										log("login failed. no cookie", data, textStatus, jqXHR);
										$(options.idUsername, form).addClass("error");
										$(options.idPassword, form).addClass("error");
									}
									else {
										log("succesfully logged in", data, textStatus, jqXHR);
										$(options.idLoginLayer).text(options.textLogout);
										$(options.idLoginLayer).addClass("logged_in");
										$("input", form).removeClass("error");

										if ($(options.idSaveLogin, form).is(':checked')) {
											createCookieSeconds("saveLogin", "true", cookieTimeout);
										} else {
											eraseCookie("saveLogin");
											eraseCookie("activityWatch");
											createCookieSeconds("activityWatch", "active", 3600);
										}

										$(options.idUsername, form).val("");
										$(options.idPassword, form).val("");
										
										try {
											_gaq.push(['_setCustomVar', 2, 'Login', 'Ja', 2]);
											_gaq.push(['_trackPageview']);
										} catch(e) {
											log("ERROR: Google tracking failed in llLogin()", e);
										}

										if (options.redirectAfterLoginUrl !== "") {
											self.location.href = options.redirectAfterLoginUrl;
										} else {
											window.location.reload();
										}
									}
									// reinitialize loginBox
									$(options.idLoginForm).llLogin(options);
									$(options.idLoginLayer).llLoginLayer(options);
								}
							)
							.error(
								function (jqXHR, textStatus, errorThrown) {
									log("login failed", jqXHR, textStatus, errorThrown);

									switch (jqXHR.status) {
										case 403:
											$errorMessage.html(options.errors.error403);	// Nicht gefunden
											break;
										case 409:
											$errorMessage.html(options.errors.error409);	// Account nicht aktiviert
											break;
										case 410:
											$errorMessage.html(options.errors.error410);	// Gesperrt
											break;
										case 500:
											$errorMessage.html(options.errors.error500);	// Systemfehler
											break;
									}
	
									$errorMessage.slideDown();
	
									$(options.idUsername, form).addClass("error");
									$(options.idPassword, form).addClass("error");
	
									$(options.idPassword, form).val("");
	
									$(options.idLoginForm).llLogin(options);
									$(options.idLoginLayer).llLoginLayer(options);
								}
							);
						}
						return false;
					}); // submit function
			}
		);
	};


	$.fn.llLoginExt = function(opts) {
		return this.each(
			function () {
				var that = this;
				var options = $.extend({
					"cache": false,
					"cookiename": "communitycookie",
					"idLoginBox": LL.defaults.idLoginBox,
					"idLoginForm": LL.defaults.idLoginForm,
					"idLoginLayer": LL.defaults.idLoginLayer,
					"idUsername": LL.defaults.idLoginForm + "_0",
					"idPassword": LL.defaults.idLoginForm + "_1",
					"idSaveLogin": LL.defaults.idLoginForm + "_saveLogin",
					"textLogin": LL.defaults.textLogin,
					"textLogout": LL.defaults.textLogout,
					"urlLayer" : "",
					"urlLogin": LL.defaults.extLoginUrl,
					"urlLogout": LL.defaults.urlLogout,
					"postLoginAction" : function () {},
					"redirectAfterLoginUrl": LL.defaults.loginLayerOptions.redirectAfterLoginUrl,
					"redirectExtLoginNotFoundUrl": LL.defaults.loginLayerOptions.redirectExtLoginNotFoundUrl,
					"success" : function () {}
				}, opts);

				var $errorMessage = $(".error_message", this);

				// check for cookie
				var cookie = readCookie(options.cookiename);

				if (cookie === null) { // not logged in
					$(this).slideDown();
				}
				else { // logged in
					$(this).slideUp();
					return;
				}

				var form = this;
				var submit = true;
				var cookieTimeout = 10 * 365 * 86400;	// 10 Jahre in Sekunden

				$("input", this).removeClass("error");
				$errorMessage.html('').hide();

				if (options.facebookid_externalid === "" || options.facebookid_externalid === "undefined") {
					submit = false;
					$errorMessage.append('Die Facebook-Authorisierung ist fehlgeschlagen!');
					$errorMessage.show();
				}

				var data = "externalIdentifierName=facebookid_externalid";
				data += "&externalIdentifierValue="+options.facebookid_externalid;

				try {
					data += "&externalAccessToken=" + FB.getAuthResponse().accessToken;
				} catch (e) {
					log("ERROR: FB usage in llLoginExt()", e);
				}

				if (submit) {
					$.ajax({
						"cache": options.cache,
						"data": data,
						"type": "POST",
						"url": options.urlLogin,
						"dataType": "json"
					})
					.success(
						function(data, textStatus, jqXHR) {
							cookie = readCookie(options.cookiename);

							$errorMessage.slideUp();

							try {
								if (cookie === null) {
									log("login failed. no cookie", data, textStatus, jqXHR);
								} else {
									log("succesfully logged in", data, textStatus, jqXHR);
									$(options.idLoginLayer).text(options.textLogout);
									$(options.idLoginLayer).addClass("logged_in");

									if ($(options.idSaveLogin, form).is(':checked')) {
										createCookieSeconds("saveLogin", "true", cookieTimeout);
									} else {
										eraseCookie("saveLogin");
										eraseCookie("activityWatch");
										createCookieSeconds("activityWatch", "active", 3600);
									}

									if (typeof options.success === "function") {
										options.success();
									}

									try {
										_gaq.push(['_setCustomVar', 2, 'Login', 'Ja', 2]);
										_gaq.push(['_trackPageview']);
									} catch(e) {
										log("ERROR: Google pageview tracking failed in llLoginExt()", e);
									}

									if (options.redirectAfterLoginUrl !== "") {
										self.location.href = options.redirectAfterLoginUrl;
									} else {
										window.location.reload();
									}
								}
								options.urlLogin = LL.defaults.urlLogin;
								// reinitialize loginBox
								$(options.idLoginForm).llLogin(options);
								$(options.idLoginLayer).llLoginLayer(options);
							} catch (facebookLoginException) {
								log("$.fn.llLoginExt", this, facebookLoginException);
							}
						}
					)
					.error(
						function(jqXHR, textStatus, errorThrown) {
							log("login failed", jqXHR, textStatus, errorThrown);

							switch (jqXHR.status) {
								case 403:
									$errorMessage.html(options.errorsFb.error403);	// Nicht gefunden
									var url = options.redirectExtLoginNotFoundUrl;
									url += "?init=true";
									url += "&" + data;
									url += "&externalServiceName=Facebook";
									url += "&externalAccountName=" + options.facebook_name;
									url += "&externalAccountLink=" + options.facebook_link;
									url += "&email=" + ((options.facebook_email != undefined) ? options.facebook_email : "");
									//url += "&username=" + options.facebook_username;
									url += "&firstname=" + options.facebook_firstname;
									url += "&familyname=" + options.facebook_lastname;
									url += "&anrede=" + options.facebook_gender;

									if (options.redirectExtLoginNotFoundUrl !== "") {
										self.location.href = url;
									}
									break;
								case 409:
									$errorMessage.html(options.errorsFb.error409);	// Account nicht aktiviert
									break;
								case 410:
									$errorMessage.html(options.errorsFb.error410);	// Gesperrt
									break;
								case 500:
									$errorMessage.html(options.errorsFb.error500);	// Systemfehler
									break;
							}

							$errorMessage.show();

							$(options.idLoginForm).llLogin(options);
							$(options.idLoginLayer).llLoginLayer(options);
						}
					);
				}

				return false;
			}
		);
	};


	$.fn.llLoginLayer = function (opts) {

		return this.each(
			function () {
				var that = this;
				var options = $.extend(LL.defaults.loginLayerOptions, opts);

				// check for cookie
				var cookie = readCookie(options.cookiename);

				if (cookie === null) { // not logged in

					$(this).text(options.textLogin);
					$(this).removeClass("logged_in");

					$(this)
						.unbind("click")
						.click(
							function (e) {
								e.preventDefault();

								LL.actions.openLoginLayer(that, options);
								try {
									_gaq.push(['_trackPageview', '/anmeldung/overlay']);
								} catch(e) {
									log("ERROR: Google tracking failed in llLoginLayer()", e);
								}
							}
						);// .click

				} else { // logged in
					$(this).text(options.textLogout);
					$(this).addClass("logged_in");

					$(this)
						.unbind("click")
						.click(
							function () {
								// Den Login-Status *vor* dem Logout zurücksetzen:
								if (typeof options.logoutCallback == "function") {
									options.logoutCallback();
								}

								$.ajax({
									"cache": options.cache,
									"data": "nocache="+(Math.random()*100000),
									"dataType": "text",
									"type": "POST",
									"url": options.urlLogout
								})
								.success(function(data, textStatus, jqXHR) {
									log("succesfully logged out", data, textStatus, jqXHR);
									eraseCookie("saveLogin");
									eraseCookie("activityWatch");
									cookie = readCookie(options.cookiename);
									if (cookie === null) {
										$(that).text(options.textLogin);
										$(that).removeClass("logged_in");

										// reinitialize loginBox
										$(options.idLoginForm).llLogin(options);
										$(options.idLoginLayer).llLoginLayer(options);

										try {
											_gaq.push(['_trackPageview', '/anmeldung/overlay']);
										} catch(e) {
											log("ERROR: Google tracking failed in llLoginLayer()", e);
										}

										window.location.reload();
									}
								})
								.error(
									function (jqXHR, textStatus, errorThrown) {
										log("logout failed", jqXHR, textStatus, errorThrown);

										$(options.idLoginForm).llLogin(options);
										$(options.idLoginLayer).llLoginLayer(options);
										
										try {
											_gaq.push(['_trackPageview', '/anmeldung/overlay']);
										} catch(e) {
											log("ERROR: Google tracking failed in llLoginLayer()", e);
										}
									}
								);

								return false;
							}
						);
				}
			}
		);
	};


	LL.actions.openLoginLayer = function (domObj, options) {
		LL.actions.klick_navigation_anmelden_lightbox_open();
		LL.initFacebookLogin();

		$("#layer").llLayerInject({
			"callback": function () {
				if (options.focusId) {
					var elem = document.getElementById(options.focusId);
					if (elem) {
						elem.focus();
					}
				}

				$("#layer form")
					.unbind("submit")
					.submit(
						function () {
							var form = this;
							var data = $(this).serializeArray();
							var submit = true;
							var cookieTimeout = 10 * 365 * 86400;	// 10 Jahre in Sekunden

							if ($(options.idSaveLogin, form).is(':checked')) {
								data.push({ "name": "cookieTimeout", "value": (cookieTimeout) });
							}

							$("input", this).removeClass("error");

							var $errorLayerMessage = $("#login_layer_error");
							$errorLayerMessage.html('').hide();

							if ($(options.idUsername, this).val() === "") {
								$(options.idUsername, this).addClass("error");
								submit = false;
								$errorLayerMessage.append('Benutzername / E-Mail darf nicht leer sein!').show();
							}
							if ($(options.idPassword, this).val() === "") {
								$(options.idPassword, this).addClass("error");
								submit = false;
								$errorLayerMessage.append('<br/>Das Passwort darf nicht leer sein!').show();
							}

							if (submit) {
								$.ajax({
									"cache": options.cache,
									"data": data,
									"type": "POST",
									"url": options.urlLogin
								})
								.success(
									function (data, textStatus, jqXHR) {
										cookie = readCookie(options.cookiename);

										if (cookie === null) {
											LL.actions.klick_anmelden_lightbox_senden_fehler();
											log("login failed. no cookie", data, textStatus, jqXHR);
											$(options.idUsername, form).addClass("error");
											$(options.idPassword, form).addClass("error");
										}
										else {
											LL.actions.klick_anmelden_lightbox_ok_close();
											log("succesfully logged in", data, textStatus, jqXHR);
											$(options.idUsername, form).addClass("error");

											if ($(options.idSaveLogin, form).is(':checked')) {
												createCookieSeconds("saveLogin", "true", cookieTimeout);
											} else {
												eraseCookie("saveLogin");
												eraseCookie("activityWatch");
												createCookieSeconds("activityWatch", "active", 3600);
											}

											$(domObj).text(options.textLogout);
											$(domObj).addClass("logged_in");
											$("input", form).removeClass("error");
											$("#layer").fadeOut();

											try {
												_gaq.push(['_setCustomVar', 2, 'Login', 'Ja', 2]);
												_gaq.push(['_trackPageview']);
											} catch(e) {
												log("ERROR: Google tracking failed in openLoginLayer()", e);
											}

											options.postLoginAction();
											var timeout = 1000;
											if (options.isQuestionAsked === "true") {
												timeout = 0;
											}
											window.setTimeout(
												function () {
													if (options.redirectAfterLoginUrl !== "") {
														self.location.href = options.redirectAfterLoginUrl;
													} else {
														window.location.reload();
													}
												},
												timeout
											);
										}
										// reinitialize loginBox
										$(options.idLoginForm).llLogin(options);
										$(options.idLoginLayer).llLoginLayer(options);
									}
								)
								.error(
									function (jqXHR, textStatus, errorThrown) {
										LL.actions.klick_anmelden_lightbox_senden_fehler();
										log("login failed", jqXHR, textStatus, errorThrown);
										$(options.idUsername, form).addClass("error");
										$(options.idPassword, form).addClass("error");

										switch(jqXHR.status) {
										case 403:
											$errorLayerMessage.html(options.errors.error403).show();	// Nicht gefunden
											break;
										case 409:
											$errorLayerMessage.html(options.errors.error409).show();	// Account nicht aktiviert
											break;
										case 410:
											$errorLayerMessage.html(options.errors.error410).show();	// Gesperrt
											break;
										case 500:
											$errorLayerMessage.html(options.errors.error500).show();	// Systemfehler
											break;
										}

										$(options.idLoginForm).llLogin(options);
										$(options.idLoginLayer).llLoginLayer(options);
									}
								);
							} // if (submit)
							return false;
						}
				);
			}, // "callback": function
			"closeCallback": function () {
				LL.actions.klick_anmelden_lightbox_manuell_close();
			},
			"html": options.html
		}); // $.llLayerInject

		$('#community_layer_facebook_login').click(function(e) {
			var that = this;
			var $div = $(that).closest("div.right");

			try {
				FB.login(function(response) {
					if (response.authResponse) {
						FB.api('/me', function(response) {
							// Werte: response.id,.name,.first_name,.last_name,.link,.username,.gender,.locale,.age_range,.email

							var loginExtOptions = $.extend(options, {
								"facebookid_externalid": response.id,
								"facebook_email": response.email ? response.email : "",
								"facebook_username": response.username,
								"facebook_name": response.name ? response.name :"",
								"facebook_link": response.link ? response.link : "",
								"facebook_firstname": response.first_name ? response.first_name : "",
								"facebook_lastname": response.last_name ? response.last_name : "",
								"facebook_gender": response.gender ? response.gender : "",
								"errorsFb": {
									"error403": "Unbekannes Facebook-Konto. Weiterleitung zur Registrierung...",	// Nicht gefunden
									"error409": options.errors.error409,	// Account nicht aktiviert
									"error410": options.errors.error410,	// Gesperrt
									"error500": options.errors.error500		// Systemfehler
								},
								"success": function() {
									LL.actions.klick_anmelden_lightbox_ok_close();
									$("#layer").fadeOut();
								},
								"urlLogin": LL.defaults.extLoginUrl
							});

							$div.llLoginExt(loginExtOptions);
						});
					} else {
						var error = "Login fehlgeschlagen.";
						if (response.status === "not_authorized") {
							error = "Der Facebook-Login-Service hat die Anfrage abgelehnt.";
						} else {
							error = "Der Facebook-Login-Service ist nicht erreichbar oder wurde abgebrochen.";
						}
						$(".error_message", $div).html(error).show();
					} 
				}, {scope: "email"});
			} catch (ex) {
				// it's just a bug in the facebook API in Internet Explorer 7 to 9
				log("LL.actions.openLoginLayer", this, "FB-Login-Error: " + ex);
			}
			e.stopPropagation();
			return false;
		});
	};


	$.fn.llMedSearchPaging = function(opts) {
		var options = $.extend({
			"limit": 25,
			"pagingDivId": "#medicine_paging"
		}, opts);

		return this.each(function() {
			var that = this;

			var $list         = $("ul.list", this);
			var $listElements = $("ul.list > li", this);
			var $paging       = $(options.pagingDivId);
			var activePagingGroupIndex = 0;

			// paginate text
			var listSize = $listElements.size();
			var pagingGroups = [];
			var pagingGroupsLength = 0;
			var pagingGroupIndex = 0;
			var count = 0;

			// break if there ain't enough content for paging
			if (listSize <= options.limit) {
				return;
			}

			$listElements.each(function(index, element) {
				var $element = $(element);

				if (count >= options.limit) {
					pagingGroupIndex += 1;
					count = 0;
				}

				// open up new paging group if empty
				if (count == 0) {
					pagingGroups[pagingGroupIndex] = $("<div class=\"paging_group\"/>");
				}

				// push element into group
				pagingGroups[pagingGroupIndex].append($element.clone(true));

				count++;
			});

			pagingGroupsLength = pagingGroups.length;

			// wrap original elements and hide
			var $original = $("<div class=\"original\" />");
			$original.html($list.html());
			$list.html($original);

			// add clones to page
			$(pagingGroups).each(function() {
				$list.append(this);
			});

			// show first pagingGroup
			pagingGroups[activePagingGroupIndex].show();

			var gotoPage = function() {
				$(".paging_group", that).hide();
				pagingGroups[activePagingGroupIndex].show();
			};

			// build paging module
			var buildPaging = function() {
				var pagingSkel = [];
				var $pagingSkel;
				var gapSetPrevious = false;
				var gapSetAfter = false;

				pagingSkel.push("<div><p>");
				pagingSkel.push("<a class=\"previous"+ (activePagingGroupIndex > 0 ? " prev_active" : "") +"\" href=\"#"+that.id+"\" title=\"vorherige Seite\">zur&uuml;ck</a>");
				pagingSkel.push("<span class=\"btns\">");
				for(var i = 0; i < pagingGroupsLength; i+=1) {
					if (i < 3 || i >= pagingGroupsLength-3 || i === activePagingGroupIndex-1 || i === activePagingGroupIndex || i === activePagingGroupIndex+1) {
						pagingSkel.push("<a class=\"btn" + (i === activePagingGroupIndex ? " active" : "") + "\" href=\"#"+that.id+"\" title=\"Seite " + (i+1) + "\">" + (i+1) + "</a>");
					}
					else {
						if (i < activePagingGroupIndex && gapSetPrevious === false) {
							pagingSkel.push("<span class=\"btn\"> ... </span>");
							gapSetPrevious = true;
						}
						if (i > activePagingGroupIndex && gapSetAfter === false) {
							pagingSkel.push("<span class=\"btn\"> ... </span>");
							gapSetAfter = true;
						}
					}
				}
				pagingSkel.push("</span>");
				pagingSkel.push("<a class=\"next"+ (activePagingGroupIndex < pagingGroupsLength-1 ? " next_active" : "") +"\" href=\"#"+that.id+"\" title=\"n&auml;chste Seite\">n&auml;chste Seite</a>");
				pagingSkel.push("</p></div>");

				$pagingSkel = $(pagingSkel.join(""));

				// add events
				$pagingSkel
					.find(".next")
					.click(function() {
						if (!$(this).hasClass("next_active")) { return; }
						activePagingGroupIndex += 1;
						gotoPage();
						buildPaging();
					});

				$pagingSkel
					.find(".previous")
					.click(function() {
						if (!$(this).hasClass("prev_active")) { return; }
						activePagingGroupIndex -= 1;
						gotoPage();
						buildPaging();
					});

				$pagingSkel
					.find("a.btn")
					.click(function() {
						activePagingGroupIndex = parseInt($(this).text())-1;
						gotoPage();
						buildPaging();
					});

				$paging.html($pagingSkel);
			};
			buildPaging();
		});
	};


	$.fn.llModExperts = function() {
		
		return this.each(function() {
			var $li = $("li", this);

			// iterate over each answer
			$li.each(function() {
				var $text = $(".text", this),
					$hide = $(".hide", this),
					$dots = $("<span class=\"dots\">...</span>"),
					$switch = $("<div class=\"switch\"><a class=\"more\">Erweitern</a></div>");
				
				// toggling text visibility
				$switch
					.toggle(
						function() {
							$hide
								.show();
							
							$dots
								.hide();
							
							$switch
								.find(".more")
								.text("Reduzieren");
						},
						function() {
							$hide
								.hide();
							
							$dots
								.show();
							
							$switch
								.find(".more")
								.text("Erweitern");
						});
				
				// only apply switch if there is text to be hidden
				if ( $hide.size() > 0 ) {
					$hide
						.before($dots)
						.hide();
					
					$text
						.append($switch);
				}
			});
			
		});
	};


	$.fn.llPaging = function(opts) {
		var options = $.extend({
			"activePageIndex": 0,
			"limit": 0
		}, opts);

		return this.each(function() {
			var that = this;

			var $article = $(".article > .txt", this);
			var $articleElements = $(".article > .txt > *", this);
			var $paragraphs = $(".article > .txt p", this);
			var $paging = $("#paging", this);
			var activePagingGroupIndex = options.activePageIndex;

			// paginate text
			var autoPaging = (options.limit > 0);
			var charCount = 0;
			var elementsCount = $articleElements.size();
			var paragraphCount = $paragraphs.size();
			var pagingGroups = [];
			var pagingGroupIndex = 0;
			var pagingGroupsLength = 0;

			var $lastElement = null;

			$articleElements.each(function(index, element) {
				var $element = $(element);

				// element is a paragraph
				if ($element.is("h6") || (autoPaging && $element.is("p"))) {

					// limit reached and there is another paragraph and last element wasn't a h1-h6
					if ($element.is("h6") || (autoPaging && (charCount > options.limit && pagingGroupIndex < paragraphCount-1 && !$lastElement.is("h1, h2, h3, h4, h5, h6")))) {
						// set index for new pagingGroup
						pagingGroupIndex += 1;

						// reset charCount
						charCount = 0;
					} else {
						charCount += $.trim($element.text()).length;
					}
				}

				// open up new paging group if non-existent
				if (typeof pagingGroups[pagingGroupIndex] === "undefined") {
					pagingGroups[pagingGroupIndex] = $('<div class="paging_group"/>');
				}

				// set last element
				$lastElement = $element;

				// push element into group
				pagingGroups[pagingGroupIndex].append($element.clone(true));
			});

			pagingGroupsLength = pagingGroups.length;

			// break if there ain't enough content for paging
			if (pagingGroupsLength < 2) { return; }

			// wrap original elements and hide
			var $original = $('<div class="original" />');
			$original.html($article.html());
			$article.html($original);

			// add clones to page
			$(pagingGroups).each(function() {
				$article.append(this);
			});

			// show first pagingGroup
			pagingGroups[activePagingGroupIndex].show();

			// build paging module
			var buildPaging = function() {
				var pagingSkel = [];
				var $pagingSkel;
				var gapSetPrevious = false;
				var gapSetAfter = false;
				var prevIndex = (activePagingGroupIndex > 0) ? (activePagingGroupIndex - 1) : 0;
				var nextIndex = (activePagingGroupIndex < pagingGroupsLength-1) ? (activePagingGroupIndex + 1) : 0;

				pagingSkel.push('<div><p>');
				pagingSkel.push('<a class="previous' + (activePagingGroupIndex > 0 ? ' prev_active' : '') + '" href="?page=' + (prevIndex + 1) + '#pagingTop" title="vorherige Seite">zur&uuml;ck</a>');
				pagingSkel.push('<span class="btns">');

				for (var i = 0; i < pagingGroupsLength; i+=1) {
					if (i < 3 || i >= pagingGroupsLength-3 || i === activePagingGroupIndex-1 || i === activePagingGroupIndex || i === activePagingGroupIndex+1) {
						pagingSkel.push('<a class="btn' + (i === activePagingGroupIndex ? ' active' : '') + '" href="?page=' + (i+1) + '#pagingTop" title="Seite ' + (i+1) + '">' + (i+1) + '</a>');
					}
					else {
						if (i < activePagingGroupIndex && gapSetPrevious === false) {
							pagingSkel.push('<span class="btn"> ... </span>');
							gapSetPrevious = true;
						}
						if (i > activePagingGroupIndex && gapSetAfter === false) {
							pagingSkel.push('<span class="btn"> ... </span>');
							gapSetAfter = true;
						}
					}
				}
				pagingSkel.push('</span>');
				pagingSkel.push('<a class="next' + (activePagingGroupIndex < pagingGroupsLength-1 ? ' next_active' : '') + '" href="?page=' + (nextIndex + 1) + '#pagingTop" title="n&auml;chste Seite">n&auml;chste Seite</a>');
				pagingSkel.push('</p></div>');

				$pagingSkel = $(pagingSkel.join(""));

				$paging.html($pagingSkel);
			};
			buildPaging();

			// Original-HTML entfernen, damit der Code (und die IDs) nicht doppelt ist (sind).
			$original.html("");
		});
	};

	$.fn.llTeaserClipPaging = function( options ) {

		return this.each(function() {
			var $paging = $(".paging", this);
			var $links = $(".paging a", this);
			var $pages = $(".clip_page", this);
			var activePagingGroupIndex = 0;
			var pagingGroupsLength = $pages.length;

			var gotoPage = function() {
				LL.actions.klick_teaser_clip_paging( { "page": activePagingGroupIndex } );
				var $thisPage = $pages.eq(activePagingGroupIndex);

				$pages.addClass("hidden");
				$thisPage
					.removeClass("hidden")
					.find(".lazy")
					.trigger("appear");
			};

			// build paging module
			var buildPaging = function() {
				var pagingSkel = [];
				var $pagingSkel;
				var gapSetPrevious = false;
				var gapSetAfter = false;

				pagingSkel.push("<p>");
				pagingSkel.push("<a class=\"previous"+ (activePagingGroupIndex > 0 ? " prev_active" : "") +"\" href=\"#\" title=\"vorherige Seite\">zur&uuml;ck</a>");
				pagingSkel.push("<span class=\"btns\">");
				for(var i = 0; i < pagingGroupsLength; i+=1) {
					if (i < 3 || i >= pagingGroupsLength-3 || i === activePagingGroupIndex-1 || i === activePagingGroupIndex || i === activePagingGroupIndex+1) {
						pagingSkel.push("<a class=\"btn" + (i === activePagingGroupIndex ? " active" : "") + "\" href=\"#\" title=\"Seite " + (i+1) + "\">" + (i+1) + "</a>");
					}
					else {
						if (i < activePagingGroupIndex && gapSetPrevious === false) {
							pagingSkel.push("<span class=\"btn\"> ... </span>");
							gapSetPrevious = true;
						}
						if (i > activePagingGroupIndex && gapSetAfter === false) {
							pagingSkel.push("<span class=\"btn\"> ... </span>");
							gapSetAfter = true;
						}
					}
				}
				pagingSkel.push("</span>");
				pagingSkel.push("<a class=\"next"+ (activePagingGroupIndex < pagingGroupsLength-1 ? " next_active" : "") +"\" href=\"#\" title=\"n&auml;chste Seite\">n&auml;chste Seite</a>");
				pagingSkel.push("</p>");

				$pagingSkel = $(pagingSkel.join(""));

				// add events
				$pagingSkel
					.find(".next")
					.click(function(e) {
						e.preventDefault();
						if (!$(this).hasClass("next_active")) { return; }
						activePagingGroupIndex += 1;
						gotoPage();
						buildPaging();
					});

				$pagingSkel
					.find(".previous")
					.click(function(e) {
						e.preventDefault();
						if (!$(this).hasClass("prev_active")) { return; }
						activePagingGroupIndex -= 1;
						gotoPage();
						buildPaging();
					});

				$pagingSkel
					.find("a.btn")
					.click(function(e) {
						e.preventDefault();
						activePagingGroupIndex = parseInt($(this).text())-1;
						gotoPage();
						buildPaging();
					});

				$paging.html($pagingSkel);
			};
			buildPaging();

		});
	};


	$.fn.ll_pollLayer = function(opts) {
		var widgetDiv = opts;
		
		var options = $.extend({
		}, opts);
		
		return this.each(function() {
			var $that = $(this);
			var $form = $("form", this);

			$form.live("submit", function() {
				var formData = $(this).serializeArray();
				var postUrl = $(this).attr("action");
				$.ajax({
					"cache": false,
					data: formData,
					type: "POST",
					url: postUrl
				})
				.success(function(data, textStatus, jqXHR) {
					$("#"+widgetDiv).hide();
					
					$("#"+widgetDiv+'_r')
						.html(data)
						.show();

					$("#"+widgetDiv+'_r')
						.find(".close")
						.live("click", function() {
							$("#"+widgetDiv+'_r').hide();
						});

					LL.actions.klick("klick_voting_submit");

					return false;
				})
				.error(function(jqXHR, textStatus, errorThrown) {
					log(jqXHR, textStatus, errorThrown);
				});

				return false;
			});
		});
	};

	
	$.fn.llQuestion = function() {
		return this.each(function() {
			var $prev = $(".prev", this);
			var $next = $(".next", this);
			var $lists = $(".list", this);
			var listCount = $(".list", this).size();
			var index = 0;

			$prev.live("click", function(e) {
				e.preventDefault();
				LL.actions.klick_f_a_box_pfeil_zurueck();

				index -= 1;

				$lists
					.hide()
					.eq(index)
					.show();

				index >= listCount-1 ?
					$next.hide():
					$next.show();

				index <= 0 ?
					$prev.hide():
					$prev.show();
			});

			$next.live("click", function(e) {
				e.preventDefault();
				LL.actions.klick_f_a_box_pfeil_vor();

				index += 1;

				$lists
					.hide()
					.eq(index)
					.show();

				index >= listCount-1 ?
					$next.hide():
					$next.show();

				index <= 0 ?
					$prev.hide():
					$prev.show();
			});

			$next.show();
		});
	};


	$.fn.llRate = function() {
		return this.each(function() {
			var $form = $("form", this),
				$submit = $(".ratingbtn", this),
				$badge = $(".badge", this);

			function updateRating(data) {
				$.ajax({
					"cache": false,
					"data": data,
					"type": $form.prop("method"),
					"url": $form.prop("action")
				})
				.success(function(data, textStatus, jqXHR) {
					// write respone into badge and show no. of votes
					$badge.html(data);
				})
				.error(function(jqXHR, textStatus, errorThrown) {
					// error
					log("$.fn.llRate", this, jqXHR, textStatus, errorThrown);
				});
			}

			$form
				.submit(function(e) {
					e.preventDefault();

					// disable submit button
					$submit.prop("disabled", true);
					$submit.addClass("disabled");

					// send vote
					var data = $(this).serializeArray();
					updateRating(data);
				});

			// init (w/o data)
			updateRating();
		});
	};


	$.fn.llIndexBox = function() {
		return this.each(function() {
			var $more = $(".more", this);
			var $less = $(".less", this);
			var $li = $("li.hide", this);

			$more
				.click(function(e) {
					e.preventDefault();
					$more.hide();
					$less.show();
					$li.show();
				});

			$less
				.click(function(e) {
					e.preventDefault();
					$less.hide();
					$more.show();
					$li.hide();
				});
		});
	};


	$.fn.llSearchLayer = function( options ) {
		var opts = $.extend({
			"url": null,
			"defaultValue": ""
		}, options);
		return this.each(function() {
			var $form = $(this);
			var $input = $(".text", this);
			var $layer = $(".suggest", this);

			var sending = false;
			var layerIndex;
			var value = $input.val();

			$form.attr("autocomplete", "off");
			$input.attr("autocomplete", "off");

			// Den Standardtext beim ersten Fokussieren entfernen und die Farbe anpassen:
			$input.focus(function(e) {
				if ($input.val() == opts.defaultValue) {
					$input.val("");
					$input.removeClass("default");
				}
			});

			// Autocomplete-Layer ausblenden, wenn man woanders auf die Seite klickt. Da das Text-
			// feld aber auch beim Klick auf einen Autocomplete-Eintrag den Focus verliert, einen
			// kleinen Moment warten, bevor man es ausblendet.
			$input.blur(function(e) {
				var hideTimer = null;
				window.clearTimeout(hideTimer);
				hideTimer = window.setTimeout(function() {
					$layer.html("").hide();
				}, 300);
			});

			$form.submit(function(e) {
				if ($input.val() == opts.defaultValue) {
					$input.val("");
					$input.removeClass("default");
				}
			});

			$form.keyup(function(e) {
				var inputLength = $input.val().length;

				// escape
				if ( e.keyCode === 27) {
					$layer
						.html("")
						.hide();

					layerIndex = undefined;

					$input.text( $input.val() );
					return;
				}

				// up
				if ( e.keyCode === 38 ) {
					var $results = $layer.find("li li");
					var resultCount = $results.size();

					layerIndex == undefined ? layerIndex = resultCount - 1 : layerIndex = getIndex( layerIndex - 1, resultCount );

					$results
						.removeClass("active")
						.eq( layerIndex )
						.addClass("active");

					$input.val( $results.eq( layerIndex ).find(".term").text() );

					return;
				}

				// down
				if ( e.keyCode === 40 ) {
					var $results = $layer.find("li li");
					var resultCount = $results.size();

					layerIndex == undefined ? layerIndex = 0 : layerIndex = getIndex( layerIndex + 1, resultCount );

					$results
						.removeClass("active")
						.eq( layerIndex )
						.addClass("active");

					$input.val( $results.eq( layerIndex ).find(".term").text() );

					return;
				}

				// enter
				if ( e.keyCode === 13 && layerIndex != undefined ) { // enter
					var $results = $layer.find("li li");
					$input.val( $results.eq( layerIndex ).find(".term").text() );

					$layer
						.html("")
						.hide();
					layerIndex = undefined;

					return;
				}
				else if ( e.keyCode === 13 ) {
					$layer
						.html("")
						.hide();

					layerIndex = undefined;

					$form.submit();
					return;
				}


				if ( inputLength >= 2 && sending === false && value !== $input.val() ) {
					value = $input.val();
					sending = true;
					inputLengthSent = inputLength;

					$.ajax({
						"url": opts.url,
						"data": {
							"search": value
						}
					})
					.success(function(data, textStatus, jqXHR) {
						if ( $(data).size() !== 0 ) {
						$layer
							.html( data )
							.show();
						}
						else {
							$layer
								.html("")
								.hide();
						}

						layerIndex = undefined;
						sending = false;

						if (	inputLength !== inputLengthSent ) {
							$input.trigger("keyup");
						}
					})
					.error(function(jqXHR, textStatus, errorThrown) {
						log(jqXHR, textStatus, errorThrown);
					});
				}
			});

		});
	};


	$.fn.llSelftestAnswers = function() {
		return this.each(function() {
			var $input      = $("input", this);
			var $answerDesc = $(".answer_desc", this);
			var that        = this;

			$input.click(function() {
				$answerDesc.each(function() {
					var id      = $(this).attr("id").replace("_desc", "");
					var $answer = $("#" + id, that);

					if ($answer.is(':checked')) {
						$(this).show();
					} else {
						$(this).hide();
					}
				});
			});
		});
	};


	$.fn.llShortcuts = function(opts) {
		var options = $.extend({
			"duration": 500
		}, opts);

		return this.each(function() {
			var $hl = $(".hl", this);
			var $lists = $(".list", this);
			var count = $lists.size();

			$hl
				.css("cursor", "pointer")
				.click(function(e) {
					e.preventDefault();

					var that = this;

					$hl
						.not(this)
						.each(function() {
							var $list = $(this).siblings(".ilist");
							var $parent = $(this).closest(".list");

							$list.slideUp(
								options.duration,
								function() {
									$parent.removeClass("active");
								});
						});

					$(this)
						.each(function() {
							var $list = $(this).siblings(".ilist");
							var $parent = $(this).closest(".list");

							$list.slideDown(
								options.duration,
								function() {
									$parent.addClass("active");
								});
						});
				});
		});
	};


	$.fn.llSitemapToggle = function() {
		return this.each(function() {
			var that = this;

			$("li.sub > a", this).click(
					function() {
						if ($(this).next(".toggle").is(":hidden")) {
							// open
							if ($(this).closest("li").hasClass("fl")) {
								$(".fl.open", that)
									.removeClass("open")
									.children(".toggle:first")
									.slideUp();
							}

							$(this)
								.closest("li")
								.addClass("open")
								.end()
								.next(".toggle")
								.slideDown();
						}
						else {
							// close
							$(this)
								.next(".toggle")
								.slideUp()
								.end()
								.closest("li")
								.removeClass("open");
						}
						return false;
					});
		});
	};


	$.fn.llNavboxToggle = function() {
		return this.each(function() {

			// click on list item
			$("li.sub", this)
				.click(function( e ) {
					// stop bubbling up the DOM
					e.stopPropagation();

					// get click position
					var x = e.pageX - $(this).offset().left;
					var y = e.pageY - $(this).offset().top;

					// if not above + or - return here
					if (x < 0 || x > 24 || y < 0 || y > 35) {
						return;
					}

					// open or close the sublist for the clicked element
					if ( $(this).hasClass("open") ) {	// close
						// change icon
						$(this)
							.removeClass("open");

						// close sublist
						$("> .toggle", this)
							.slideUp(function() {
								// close all child-sublists and change icon to closed
								$(".toggle", this)
									.hide();
								$(".open", this)
									.removeClass("open");
							});
					}
					else {								// open
						// change icon
						$(this)
							.addClass("open");

						// open sublist
						$("> .toggle", this)
							.slideDown();

						// get all lists on the same layer and close them and their sublists
						$(this)
							.siblings()
							.removeClass("open")
							.find("> .toggle")
							.slideUp(function() {
								// close all child-sublists and change icon to closed
								$(".toggle", this)
									.hide();
								$(".open", this)
									.removeClass("open");
							});
					}
				});
		});
	};


	$.fn.llSlideShow = function(opts) {
		var options = $.extend({
			"duration": 500
		}, opts);

		return this.each(function() {
			var that = this;
			var $wrapper = $(".wrapper", this);
			var $list = $(".list", this);
			var $prev = $(".prev", this);
			var $next = $(".next", this);
			var $images = $(".list img", this);
			var $counter = $(".counter .idx", this);

			var index = 0;
			var elementCount = $(".list li", this).size();
			var elementWidth = 510;

			var headline = $(".hd", this).text();

			$(".control", this).show();

			var slide = function(idx, callback) {
				var offsetLeft = -5 - ((idx) * elementWidth);

				$counter.text(getIndex(idx, elementCount)+1);

				$( ".list", that )
					.animate({
						"margin-left": offsetLeft
					}, function() {
						if (typeof callback === "function") {
							callback.call();
						}
					});
			};

			var prev = function() {
				index -= 1;

				if (index < 0) {
					// duplicate & position
					var offset = -(elementCount * elementWidth)-5;

					$list
						.find("li")
						.clone(true, true)
						.prependTo($list);
					$list.css("margin-left", offset);

					slide(getIndex(index, elementCount), function() {
						$list
							.find("li:gt("+ (elementCount-1) +")")
							.remove();
						index = getIndex(index, elementCount);
					});
				}
				else {
					slide(index);
				}
			};

			var next = function() {
				index += 1;

				if (index >= elementCount) {
					// duplicate & position
					$list
						.find("li")
						.clone(true, true)
						.appendTo($list);

					slide(index, function() {
						$list
							.find("li:lt("+ elementCount +")")
							.remove()
							.end()
							.css("margin-left", "-5px");

						index = getIndex(index, elementCount);
					});
				}
				else {
					slide(index);
				}
			};

			$(this)
				.on("click", ".prev", function() {
					prev();
					LL.actions.klick_fotostrecke_zurueck({
						"headline": headline,
						"trigger": "pfeil_button"
					});
				});

			$(this)
				.on("click", ".next", function() {
					next();
					LL.actions.klick_fotostrecke_vor({
						"headline": headline,
						"trigger": "pfeil_button"
					});
				});

			$(document).keyup(function(e) {
				switch(e.keyCode) {
					case 37:
						prev();
						LL.actions.klick_fotostrecke_zurueck({
							"headline": headline,
							"trigger": "keypress"
						});
						break;
					case 39:
						next();
						LL.actions.klick_fotostrecke_vor({
							"headline": headline,
							"trigger": "keypress"
						});
						break;
				}
			});

			$images
				.css("cursor", "pointer")
				.click(function(e) {
					var relativeX = e.pageX - $(this).offset().left;
					if (relativeX <= 250) {
						prev();
						LL.actions.klick_fotostrecke_zurueck({
							"headline": headline,
							"trigger": "sensitive_area"
						});
					}
					else {
						next();
						LL.actions.klick_fotostrecke_vor({
							"headline": headline,
							"trigger": "sensitive_area"
						});
					}
				});

		});
	};


	$.fn.llTabs = function() {
		return this.each(function() {
			var $tabs = $(".tabs li", this);
			var $tabLinks = $(".tabs a", this);

			// set active state and show corresponding content
			$tabLinks.click(function() {

				var idx = $tabLinks.index(this);

				// return if this tab is already active
				if ($tabs.eq(idx).hasClass("active")) { return false; }

				// remove active states of all tabs, then set clicked active.
				$tabs
					.removeClass("active")
					.eq(idx)
					.addClass("active");

				return false;
			});
		});
	};


	$.fn.llTeaserTab = function( options ) {
		var opts = $.extend({
			"klick_function": LL.actions.klick_teaser_reiter
		}, options);

		return this.each(function() {
			var $tabs = $(".tabs a", this);
			var $teasers = $(".mod_tab", this);

			$tabs.click(function() {
				var idx = $tabs.index(this);

				$tabs.removeClass("active");
				$(this).addClass("active");
				if (typeof opts.klick_function == "function") {
					opts.klick_function( { button_name: $(this).text() } );
				}

				$teasers.removeClass("active");
				$teasers.eq(idx).addClass("active");

				return false;
			});
		});
	};


	$.fn.llTopTopics = function( options ) {
		return this.each(function() {

			// switch to zoom if option is set
			if ( options.zoom ) {
				$(this).llTopTopicsZoom( options );
				return;
			}

			var opts = $.extend({
				"slideDuration": 700,
				"imgWidth": 520,
				"interval": 5000,
				"length": 1,
				"unitWidth": 364
			}, options);

			var index = 0;
			var interval;
			var that = this;
			var $controls = $(".prev, .next", this);
			var $prev = $(".prev", this);
			var $next = $(".next", this);
			var $sliderImg = $(".slider_img .slide_cont", this);
			var $sliderTxt = $(".slider_txt .slide_cont", this);
			var $txts = $(".unit", this);

			var resizeImages = function () {
				opts.imgWidth = $(that).width();
				$("img", $sliderImg).css("width", opts.imgWidth + "px");
				$sliderImg.css("width", opts.length * opts.imgWidth);
				$sliderTxt.css("width", opts.length * opts.unitWidth);
			};

			var resizeControls = function() {
				var height = $txts.eq(index).outerHeight(false);

				$controls
					.height(height)
					.find("a")
					.css({
						"margin-top": height / 2 - 18
					});
			};

			var prev = function() {
				// img animation
				var $lastImg = $(".slider_img .img", that).last().detach();
				$sliderImg
					.css({
						"left": "-"+opts.imgWidth+"px"
					})
					.prepend($lastImg)
					.animate({
						"left": "0px"
					}, opts.slideDuration);


				// text animation
				$txts
					.eq(index)
					.css("visibility", "hidden")
					.end()
					.eq(getIndex(index-1, opts.length))
					.css("visibility", "visible");

				index = getIndex(index-1, opts.length);

				resizeControls();

				return false;
			};

			var next = function() {
				// img animation
				$sliderImg
					.animate({
						"left": "-="+opts.imgWidth+"px"
					},
					opts.slideDuration,
					function() {
						var $firstImg = $(".slider_img .img", that).first().detach();
						$sliderImg
							.css({
								"left": 0
							})
							.append($firstImg);
					});

				// text animation
				$txts
					.eq(index)
					.css("visibility", "hidden")
					.removeClass("active")
					.end()
					.eq(getIndex(index+1, opts.length))
					.css("visibility", "visible")
					.addClass("active");

				index = getIndex(index+1, opts.length);

				resizeControls();

				return false;
			};
			
			$("a", $txts).click(function() {
				try {
					_gaq.push(['_trackEvent', 'mod_top_topics_zoom', 'Teaser-Überschrift', $(this).attr('title')]);
				} catch(e) {
					log("ERROR: Google tracking failed in llTopTopics()", e);
				}
			});

			$prev
				.click(function() {
					LL.actions.klick_top_themen_pfeil_zurueck();
					window.clearInterval(interval);
					prev();
				})
				.show();
			$next
				.click(function() {
					LL.actions.klick_top_themen_pfeil_vor();
					window.clearInterval(interval);
					next();
				})
				.show();

			// start automatic rotation
			function startInterval() {
				interval = window.setTimeout(function() {
					next();
				}, options.interval);
			}

			resizeImages();

			if (opts.length > 1) {
				startInterval();
				resizeControls();
			} else {
				$controls.hide();
			}

			$(window).resize(resizeImages);
		});
	};


	$.fn.llTopTopicsZoom = function( options ) {

		var opts = $.extend({
			"fadeDuration": 1000,
			"fadeTxtDuration": 0,
			"imgWidth": 520,
			"interval": 5000,
			"length": 1,
			"zoomPixel": 60,
			"zoomDuration": 5000
		}, options);

		return this.each(function() {
			if (opts.length <= 1) { return; }

			var index = 0;
			var interval;
			var steps = Math.round( opts.zoomDuration / 48 );
			var timeouts = [];
			var $controls = $(".prev, .next", this);
			var $prev = $(".prev", this);
			var $next = $(".next", this);
			var $imgs = $(".slider_img .slide_cont .img", this);
			var $txts = $(".slider_txt .unit", this);

			function clearTimeouts() {
				window.clearInterval( interval );

				for(var t in timeouts) {
					window.clearTimeout(timeouts[t]);
				}
			}

			var resizeControls = function() {
				var height = $txts.eq(index).outerHeight(false);

				$controls
					.height(height)
					.find("a")
					.css({
						"margin-top": height / 2 - 18
					});
			};


			function animateImg( idx ) {
				$imgs
					.not( $imgs.eq(idx) )
					.fadeOut( opts.fadeDuration );

				$imgs
					.eq( idx )
					.fadeIn( opts.fadeDuration, function() {
						$imgs
							.not( $imgs.eq(idx) )
							.not( ".img_ad" )
							.css({
								"left": 0,
								"height": opts.imgWidth,
								"top": -opts.zoomPixel,
								"width": opts.imgWidth
							});
					});

				if ( !$imgs.eq(idx).hasClass("img_ad") ) {
					for(var i = 0, timeout = 0; i < 48; i += 1, timeout += steps) {
						(function(timeout, offset) {
							var t = window.setTimeout(function() {
								$imgs
									.eq( idx )
									.css({
										"height": "+=2",
										"left": "-" + offset + "px",
										"top": "-" + (opts.zoomPixel + offset) + "px",
										"width": "+=2"
									});
							}, timeout);

							timeouts.push( t );
						})(timeout, i);

					}
				}
				return false;
			}


			function animateText( idx ) {
				$txts
					.not( $txts.eq(idx) )
					.css("visibility", "hidden");

				resizeControls();

				$txts
					.eq( idx )
					.css("visibility", "visible")
					.find('a')
					.bind('click', function() {
						try{
							_gaq.push(['_trackEvent', 'mod_top_topics_zoom', 'Teaser-Überschrift', $(this).attr('title')]);
						} catch(e) {
							log("ERROR: Google tracking failed in animateText()", e);
						}
					});
			}



			var prev = function() {
				clearTimeouts();
				LL.actions.klick_top_themen_pfeil_zurueck();
				index = getIndex( index - 1, opts.length);
				animateImg(index);
				animateText(index);
				return false;
			};

			var next = function() {
				clearTimeouts();
				LL.actions.klick_top_themen_pfeil_vor();
				index = getIndex( index + 1, opts.length);
				animateImg(index);
				animateText(index);
				return false;
			};


			// bind events and show controls
			$prev
				.click(prev)
				.show();
			$next
				.click(next)
				.show();


			function startInterval() {
				interval = window.setInterval(function() {
					index = getIndex( index + 1, opts.length );
					animateImg(index);
					animateText(index);
				}, options.interval);
			}

			animateImg( 0 );
			animateText( 0 );
			startInterval();
		});
	};


	$.fn.llValidate = function() {
		return this.each(function() {
			var $form = $(this);
			var formId = $form.attr("id");
			var action = $form.attr("action");

			$form
				.submit(function() {
					var data = $form.serialize();
					$form.load( action + "?" + data + " #" + formId);
					return false;
				});
		});
	};


	$.fn.llVideo = function() {
		return this.each(function() {
			var that = this;
			var $linkVideo = $(".img_container a.img", this);

			$linkVideo.live("click", function(e) {
				e.preventDefault();

				$("#layer").llLayer({
					"closeCallback": function() {
						$("#layer").html("");
					},
					"duration": 0,
					"url": $(this).attr("href")
				});
				return false;
			});
		});
	};


	$.fn.llVideoBox = function() {
		return this.each(function() {
			var that = this;
			var $linkVideo = $(".bd a.img", this);
			var $linksBottom = $(".ft a", this);

			// add icons
			var $prev = $("<a class=\"prev\" />");
			var $next = $("<a class=\"next\" />");

			var deleteInlineScripts = function (dom) {
				return dom.replace(/<script[^>]*>[^<]*<\/script>/, '');
			};

			var shift = function($list) {

				try {
					var $clone = $list.clone();

					$list.each(function(idx, item) {
						var $replacement = $clone.eq(getIndex(idx-1, $clone.size()));

						$(item).html(deleteInlineScripts($replacement.html()));

					});
				} catch (exp) {
					/* ignore console.dir(exp); */
				}
			};

			$prev.click(function(e) {
				e.preventDefault();

				if (typeof restartInactivityTimer === "function") {
					restartInactivityTimer();
				}

				LL.actions.klick_videobox_pfeil_zurueck();

				var list = $(".inner, li", that);
				shift(list);
				return false;
			});

			$next.click(function(e) {
				e.preventDefault();

				if (typeof restartInactivityTimer === "function") {
					restartInactivityTimer();
				}

				LL.actions.klick_videobox_pfeil_vor();

				var list = $(".inner, li", that).reverse();
				shift(list);
				return false;
			});

			$(".bd", $(this))
				.append($prev)
				.append($next);

			$linkVideo.live("click", function(e) {
				e.preventDefault();

				$("#layer").llLayer({
					"closeCallback": function() {
						$("#layer").html("");
					},
					"duration": 0,
					"url": $(this).attr("href")
				});
				return false;
			});

			$linksBottom.live("click", function(e) {
				try {
					e.preventDefault();
					e.stopPropagation();
					console.dir($(this));
					LL.actions.klick_videobox_thumbnail({
						"title": $(this).attr("title")
					});

					var $top = $(".inner", that);
					var $bottom = $(this).parent().parent();
					var $topClone = $top.clone();
					var $bottomClone = $bottom.clone();

					$top.html(deleteInlineScripts($bottomClone.html()));
					$bottom.html(deleteInlineScripts($topClone.html()));

				} catch (exp) {
					console.dir(exp);
				}
				return false;
			});
		});
	};


	/**
	 * Usage:
	 * <code>
	 * jQuery.userImagize({
	 * 		"defaultImageUrls": {
	 * 			"male": "/[publication]/resources/ts21970927/ver1-0/img/avatars/avatar_04.png",
	 * 			"female": "/[publication]/resources/ts21970927/ver1-0/img/avatars/avatar_02.png",
	 * 			"expert": "/[publication]/resources/ts21970927/ver1-0/img/avatars/avatar_01.png"
	 * 		},
	 * 		"imageBasePath": "/community-webservice/service/image?actionname=show&image=",
	 * 		"imageSelector": "IMG.user_123456"
	 * });
	 * </code>
	 */
	$.userImagize = function (opts) {

		var options = $.extend(
			true,
			{
				"cookieName": "communitydata2",
				"defaultImageUrls": {
					"male": "",
					"female": "",
					"expert": ""
				},
				"imageBasePath": "", // /community-webservice/service/image?actionname=show&image=
				"imageSelector": "" // .uicon
			},
			opts
		);

		validateOptions();

		// read cookie
		var cookie = readCookie(options.cookieName);

		if (cookie !== null) {

			// examine cookie
			// - slot 0: complete match;
			// - slot 1: userId;
			// - slot 2: gender or expert or undefined;
			// - slot 3: img-query-parameter

			cookie = cookie.replace(/"/g, '');
			var parts = cookie.match(/(^[\d]*)\|(Herr|Frau|expert)?\|([^\|]*)$/);
			if (parts === null || parts[1] === "") { // regex failed ... cookie is corrupt or no userid is given
				// we have to step out ...
				return;
			}

			var $images = $(options.imageSelector + parts[1]);

			if ($images.length === 0) {
				// no images found at all, nothing more to do
				return;
			}

			// find current uri (its the same for all images, so we check on the first image found.)
			var currentSrcUri = $images[0].src;
			var newSrcUri = "";
			if (parts[3] !== "") {
				if (currentSrcUri.indexOf(parts[3]) !== -1) {
					// the image uris are correct, nothing more to do!
					return;
				} else {
					newSrcUri = options.imageBasePath + parts[3];
				}
			} else { // user has no specific image
				if (parts[2] !== undefined || parts[2] !== "") {
					switch (parts[2]) {
						case "expert":
							newSrcUri = options.defaultImageUrls.expert;
							break;
						case "Frau":
							newSrcUri = options.defaultImageUrls.female;
							break;
						case "Herr":
							newSrcUri = options.defaultImageUrls.male;
							break;
					}
				}
			}

			if (currentSrcUri === newSrcUri) {
				// the image uris are identical, nothing more to do!
				return;
			}

			if (currentSrcUri === options.defaultImageUrls.expert &&
					(newSrcUri === options.defaultImageUrls.female || newSrcUri === options.defaultImageUrls.male)) {
				// leave default expert piuc be
				return;
			}

			// suche alle relevanten imgs.
			$(options.imageSelector + parts[1]).each(
				function (idx, image) {
					$(image).attr("src", newSrcUri);
				}
			);

		}
		function validateOptions() {
			if (options.imageSelector === "") {
				throw "userImagize coder exception: options.imageSelector mustn't be empty!";
			}
			if (options.imageBasePath === "") {
				throw "userImagize coder exception: options.imageBasePath mustn't be empty!";
			}
			if (options.defaultImageUrls.male === "") {
				throw "userImagize coder exception: options.defaultImageUrls.male mustn't be empty!";
			}
			if (options.defaultImageUrls.female === "") {
				throw "userImagize coder exception: options.defaultImageUrls.female mustn't be empty!";
			}
			if (options.defaultImageUrls.expert === "") {
				throw "userImagize coder exception: options.defaultImageUrls.expert mustn't be empty!";
			}
		}
	};


	var HoroscopeWidget = function (element, date, zodiacSignsForetellings, options) {

		/**
		 * @private
		 */
		var that = this;
		/**
		 * @private
		 */
		var currentDate = date;
		/**
		 * @private
		 */
		var currentIndex = -1;
		/**
		 * @private
		 */
		var $previousHandle = $(options.previousSelector, element);
		/**
		 * @private
		 */
		var $nextHandle = $(options.nextSelector, element);
		/**
		 * @private
		 */
		var $itemContainer = $(options.itemContainerSelector, element);
		/**
		 * @private
		 */
		var $headlineElement = $(options.headlineElementSelector, element);

		zodiacSignsForetellings.sort(
			function (a, b) {
				if (a.dateFrom < b.dateFrom) {
					return -1;
				} else if (a.dateFrom > b.dateFrom) {
					return 1;
				} else { //they are equal ... shouldn't ever happen!
					return 0;
				}
			}
		);
		/**
		 * @private
		 */
		var _zodiacSignsForetellings = zodiacSignsForetellings;
		/**
		 * @private
		 */
		var itemWidth = $itemContainer.parent().width();

		/**
		 *
		 * @constructor
		 */
		var init = function () {

			drawHtmlItems();

			setEventHandler();

			// go, to where the date says ...
			currentIndex = getZodiacSignByDate(date)[0];
			this.goTo(currentIndex);

		};

		/**
		 * @public
		 */
		this.next = function () {
			this.goBy(+1);
			return element;
		}
		/**
		 * @public
		 */
		this.previous = function () {
			this.goBy(-1);
			return element;
		}
		/**
		 * @public
		 */
		this.goBy = function (indexDelta) {
			moveTo(getNewDeltaIndex(indexDelta));
			return element;
		};
		/**
		 * @public
		 */
		this.goTo = function (indexDelta) {
			moveTo(getNewIndex(indexDelta));
			return element;
		};
		/**
		 * @public
		 */
		this.setZodiacSignByDate = function (date) {
			if (isValidDate(date)) {
				this.goTo(getZodiacSignByDate(date)[0]);
			}

		}

		/**
		 * @private
		 */
		var drawHtmlItems = function () {
			// create the domElements accordingly to data
			var itemsHtml = "";
			for (var i = 0; i < _zodiacSignsForetellings.length; i++) {
				var zodiacSignsFortelling = _zodiacSignsForetellings[i];
				itemsHtml += options.templateItem
								.replace(/\$\{foreTelling.title\}/g, zodiacSignsFortelling.title)
								.replace(/\$\{foreTelling.content\}/g, zodiacSignsFortelling.content);
			}
			$itemContainer.empty().html(itemsHtml);
		}
		/**
		 * @private
		 */
		var setEventHandler = function () {
			// apply the EventHandler to next and previous handles
			$previousHandle.click(
				function () {
					element.data('horoscopeWidget').previous();
				}
			);
			$nextHandle.click(
				function () {
					element.data('horoscopeWidget').next();
				}
			);
		}
		/**
		 * @private
		 */
		var getNewDeltaIndex = function (indexDelta) {

			// setting the index in data to go to... preventint not existing indexes
			var newIndex = (currentIndex + indexDelta) % _zodiacSignsForetellings.length ;

			// checking the newIndex for congruity
			if (newIndex > _zodiacSignsForetellings.length - 1) {
				console.debug("small and porous brains guesses this impossible!");
			}
			if (newIndex < 0) {
				newIndex = _zodiacSignsForetellings.length + newIndex;
			}

			return newIndex;
		};
		/**
		 * @private
		 */
		var getNewIndex = function (index) {

			index = index % _zodiacSignsForetellings.length;
			// prevent not existing index ...
			if (index < 0) {
				return _zodiacSignsForetellings.length + index;
			}

			return index;
		};
		/**
		 * @private
		 */
		var getZodiacSignByDate = function (date) {

			var index = 0;
			var element = jQuery.grep(
				_zodiacSignsForetellings,
				function (zodiacSignsForetelling, idx) {
					if (date >= zodiacSignsForetelling.dateFrom && date <= zodiacSignsForetelling.dateTo) {
						index = idx
						return true;
					}
				}
			);
			return [index, element];
		};
		/**
		 * @private
		 */
		var isValidDate = function (date) {
			return (new RegExp(options.datePattern)).test("" + date);
		};
		/**
		 * @private
		 */
		var moveTo = function (index) {
			if (index < 0 || index > _zodiacSignsForetellings.length -1) {
				log("trial to access a none existing index in data!");
				return;
			}
			var newZodiacSign = _zodiacSignsForetellings[index];
			if (!!!newZodiacSign) {
				log("index in data lead to an undefiend data partial!");
				return;
			}
			currentIndex = index;
			$headlineElement.fadeOut(
				options.duration / 4,
				function () {
					$headlineElement.html(newZodiacSign.name);
					$itemContainer.animate(
						{
							"left": - (index * itemWidth) + "px"
						},
						options.duration / 2,
						function () {
							$headlineElement.fadeIn(
								options.duration / 4
							);
						}
					);
				}
			);
		};

		init.apply(this, arguments);

	};


	$.fn.horoscopeWidget = function (date, zodiacSignsForetellings, opts) {

		var options = $.extend(
			{
				"duration": 1000,
				"previousSelector": ".previous",
				"nextSelector": ".next",
				"itemContainerSelector": ".items ul",
				"headlineElementSelector": ".control .zodiacSign",
				"templateItem": '<li class="txt"><h6>${foreTelling.title}</h6><p>${foreTelling.content}</p></li>',
				"updateByUsersBirthday": false,
				"url": "",
				"datePattern": "\\d\\d\\d\\d-\\d\\d-\\d\\d"
			},
			opts
		);

		return this.each(
			function () {
				var element = $(this);

				if (element.data('horoscopeWidget')) { // Return early if this element already has a plugin instance
					return element.data('horoscopeWidget');
				}
				// pass options to plugin constructor
				var horoscopeWidget = new HoroscopeWidget(element, date, zodiacSignsForetellings, options);
				// Store plugin object in this element's data
				element.data('horoscopeWidget', horoscopeWidget);


				if (options.updateByUsersBirthday && options.url !== "") {
					// ask for a user and his birthday, if a valid result is retrieved, reset the HoroscopeWidget to the zodiac sign of the user ...
					$.ajax({
						"cache": false,
						"context": element,
						"data": "",
						"type": "POST",
						"url": options.url,
						"dataType": "json"
					}).success(
						function (data, textStatus, jqXHR) {
							if (!!data) {
								if (data.error) {
									log("updating HoroscopeWidget failed. Message is: ", data.error);
								}
								if (data.birthday) {
									element.data('horoscopeWidget').setZodiacSignByDate(data.birthday.replace(/^\d\d\d\d\-/, (new Date()).getFullYear() + "-"));
								}
							}
						}
					).error(
						function (jqXHR, textStatus, errorThrown) {
							log("updating HoroscopeWidget failed. Message is: ", errorThrown, textStatus, jqXHR);
						}
					);
				}
			}
		);

	};


	$(function() {
		var clicker, cE, sP, sPn;

		$('.mod_special a, .clip_page .media a').click(function() {
			clicker = $(this);
			if (clicker.hasClass('img')) {
				cE = 'Teaser-Bild'; 
			} else if (clicker.hasClass('h1')) {
				cE = 'Teaser-Überschrift'; 
			} else {
				cE = 'Teaser-mehr-Link';
			}
			try{
				_gaq.push(['_trackEvent', 'mod_special', cE, clicker.attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.mod_special a, .clip_page .media a' click", e);
			}
		});
		$('.paging .btn').live('click', function() {
			try{
				_gaq.push(['_trackEvent', 'Paging', 'Seite', $(this).val()]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.paging .btn' click", e);
			}
		});
		$('.paging .previous_active, .paging .next_active').click(function() {
			clicker = $(this).parent().find('.active');
			var nClicker = clicker.text();
			if (clicker.hasClass('previous')) {
				clicker = clicker--;
			} else {
				clicker = clicker++;
			}
			try {
				_gaq.push(['_trackEvent', 'Paging', 'Seite', nClicker]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.paging .previous_active, .paging .next_active' click", e);
			}
		});
		$('.content_main .mod_caroussel .slider .slot > a').live('mousedown', function() {
			clicker = $(this);
			if (clicker.hasClass('more')) {
				cE = 'mehr Specials'; 
			} else {
				cE = 'Teaser-Bild'; 
			}
			var sP = clicker.parent();
			if (sP.hasClass('slotBox1')) {
				sPn = 'Seite 1';
			} else if (sP.hasClass('slotBox2')) {
				sPn = 'Seite 1';
			} else if (sP.hasClass('slotBox3')) {
				sPn = 'Seite 2';
			} else if (sP.hasClass('slotBox4')) {
				sPn = 'Seite 2';
			} else if (sP.hasClass('slotBox5')) {
				sPn = 'Seite 3';
			} else if (sP.hasClass('slotBox6')) {
				sPn = 'Seite 3';
			}
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 2-spaltig' + sPn, cE, clicker.attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_main .mod_caroussel .slider .slot > a' mousedown", e);
			}
		});
		$('.content_wide .mod_caroussel .slider .slot > a').live('mousedown', function() {
			clicker = $(this);
			if (clicker.hasClass('more')) {
				cE = 'mehr Specials'; 
			} else {
				cE = 'Teaser-Bild'; 
			}
			var sP = clicker.parent();
			if (sP.hasClass('slotBox1')) {
				sPn = 'Seite 1';
			} else if (sP.hasClass('slotBox2')) {
				sPn = 'Seite 1';
			} else if (sP.hasClass('slotBox3')) {
				sPn = 'Seite 1';
			} else if (sP.hasClass('slotBox4')) {
				sPn = 'Seite 2';
			} else if (sP.hasClass('slotBox5')) {
				sPn = 'Seite 2';
			} else if (sP.hasClass('slotBox6')) {
				sPn = 'Seite 2';
			}
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 3-spaltig' + sPn, cE, clicker.attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_wide .mod_caroussel .slider .slot > a' mousedown", e);
			}
		});
		$('.content_main .mod_caroussel .slider .slot .h1 a').live('mousedown', function() {
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 2-spaltig', 'Teaser-Überschrift', $(this).attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_main .mod_caroussel .slider .slot .h1 a' mousedown", e);
			}
		});
		$('.content_wide .mod_caroussel .slider .slot .h1 a').live('mousedown',function() {
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 3-spaltig', 'Teaser-Überschrift', $(this).attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_wide .mod_caroussel .slider .slot .h1 a' mousedown", e);
			}
		});
		$('.content_main .mod_caroussel .slider .ilist a').live('mousedown',function() {
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 2-spaltig', 'Artikel-Link', $(this).attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_main .mod_caroussel .slider .ilist a' mousedown", e);
			}
		});
		$('.content_wide .mod_caroussel .slider .ilist a').live('mousedown',function() {
			try{
				_gaq.push(['_trackEvent', 'mod_caroussel 3-spaltig', 'Artikel-Link', $(this).attr('title')]);
			} catch(e) {
				log("ERROR: Google tracking failed in '.content_wide .mod_caroussel .slider .ilist a' mousedown", e);
			}
		});
	});

	// Social-Media-Leiste -------------------------------------------------------------------------

	function Socialbar( rootElement ) {
		this.$rootElement = $(rootElement);
		this.$window = $(window);
		this.$document = $(document);
		this.mobileMaxWidth = $(rootElement).data("mobile-max-width") || 767;
		this.mobileScrollThreshold = $(rootElement).data("mobile-scroll-threshold") || 500;
		this.bindEvents();
	}

	Socialbar.prototype.bindEvents = function() {
		var that = this;

		// Bind email button
		$(".socialbar__button--email", this.$rootElement).click(function (e) {
			var shareUrl = "mailto:?subject=" + encodeURIComponent($(this).data("subject")) + "&body=" + encodeURIComponent($(this).data("body"));
			e.preventDefault();
			var mail = document.createElement("a");
			mail.href = shareUrl;
			mail.click();
		});

		// Bind facebook button
		$(".socialbar__button--facebook", this.$rootElement).click(function (e) {
			var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent($(this).data("url")) + "&t=" + encodeURIComponent($(this).data("text"));
			e.preventDefault();
			window.open(shareUrl, "", "toolbar=0, status=0, width=900, height=500");
		});
		
		// Bind Pinterest button
		$(".socialbar__button--pinterest", this.$rootElement).click(function (e) {
			var url = encodeURIComponent($(this).data("url"));
			var media = encodeURIComponent($(this).data("media"));
			var description = encodeURIComponent($(this).data("description"));
			var shareUrl = "http://pinterest.com/pin/create/button/?url=" + url + "&media=" + media + "&description=" + description;
			e.preventDefault();
			window.open(shareUrl, "", "toolbar=0, status=0, width=900, height=500");
		});

		// Bind Twitter button
		$(".socialbar__button--twitter", this.$rootElement).click(function (e) {
			var shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent($(this).data("text")) + "&url=" + encodeURIComponent($(this).data("url"));
			e.preventDefault();
			window.open(shareUrl, "", "toolbar=0, status=0, width=650, height=360");
		});
		
		// Bind Whatsapp button
		$(".socialbar__button--whatsapp", this.$rootElement).click(function (e) {
			var shareText = encodeURIComponent($(this).data("text"));
			e.preventDefault();

			if (isMobileLayout()) {
				document.location.href = "whatsapp://send?text=" + shareText;
			} else {
				var shareUrl = "https://web.whatsapp.com/send?text=" + shareText;
				window.open(shareUrl, "", "toolbar=0, status=0, width=900, height=500");
			}
		});

		// Control visibility of the share bar. The bar is always visible on desktop
		// devices and hidden on mobile devices below the scrolling threshold
		that.$window.on("resize scroll", function(e) {
			var isMobile = that.mobileMaxWidth >= that.$window.width(),
				isAboveThreshold = that.$document.scrollTop() < that.mobileScrollThreshold;
				
			if (isMobile && !isAboveThreshold) {
				that.$rootElement.fadeOut(300, function() {
					$("body").removeClass("page-has-socialbar");
				});
			}
			else {
				that.$rootElement.fadeIn(300, function () {
					$("body").addClass("page-has-socialbar");
				});
			}
		});
	};

	$.fn.socialbar = function() {
		return this.each(function() {
			new Socialbar(this);
		});
	};

})(jQuery);

// Init --------------------------------------------------------------------------------------------

detectClient();

$.ajaxSetup({
	// Fix für Bug in jQuery vor 1.7.2:
	"contentType": "application/x-www-form-urlencoded; charset=UTF-8",
	// Wichtig f. iOS 6:
	"cache": "false",
	"headers": { "cache-control": "no-cache" }
});

var cookie = readCookie("communitycookie");
LL.defaults.isLoggedIn = (cookie !== null);
console.log("isLoggedIn(): " + isLoggedIn());

// TinyMCE -----------------------------------------------------------------------------------------

var tinyMceConfig = {
	script_url: tinyMceUrl,
	language: "de",
	//editor_selector : "wysiwyg",
	//mode: "specific_textareas",
	//valid_elements: "b/strong,i/em,img,br,p,p[class],u,span,span[class],div,div[class],a,a[href],a[target]",
	//valid_elements: "iframe[src][width][height][class][frameborder][title][type][allowfullscreen]",
	theme: "advanced",
	plugins: "emotions,paste,charcount",	// ,preview
	theme_advanced_resizing: true,
	theme_advanced_toolbar_location: "top",
	theme_advanced_toolbar_align: "left",
	theme_advanced_statusbar_location: "bottom",
	theme_advanced_resize_horizontal: false,
	theme_advanced_disable: "anchor,cleanup,removeformat,visualaid,charmap,hr,help,code",
	theme_advanced_buttons2_add: "separator,forecolor,separator,sup,sub,pastetext",	// ,emotions,backcolor,preview
	theme_advanced_buttons3: "",
	style_formats : [{
		title : "Zitat",
		block : "div",	// ,p
		classes: "cite"
	}],
	convert_urls : false,
	save_callback: function(fieldId, editorHtml, editorBody) {
		editorHtml = LL.substituteSmilieShortcuts(editorHtml);
		// remove all the linefeet to prevent the ForumManager from substitue them with "<br />"s!
		editorHtml = editorHtml.replace(/[\n\r]/g, "");
		//editorHtml = editorHtml.replace(/javascript/g, "");
		return editorHtml;
	},
	oninit: function () {
		//$("#melden").hide();
	}
};

var tinyMceConfigSignature = $.extend(true, {}, tinyMceConfig);
tinyMceConfigSignature.theme_advanced_disable = tinyMceConfigSignature.theme_advanced_disable + ",italic";

var tinyMCEoff = false;
if (((LL.client.os.name == "Android") && LL.client.os.version && (LL.client.os.version < 3.0)) ||
	((LL.client.os.name == "iOS") && LL.client.os.version && (LL.client.os.version < 5.0))) {
	tinyMCEoff = true;
}

/*LL.emo = "<img src=\"/resources/ver1-0/js/tiny_mce/plugins/emotions/img/";
LL.smilieReplacements = {
	':no:': LL.emo + 'extended/no.gif" alt=":no:" title="No" />',
	':sleeping:': LL.emo + 'extended/sleeping.gif" alt=":sleeping:" title="Sleeping" />',
	':biggrin:': LL.emo + 'more_smilies/biggrin.gif" id="smilie_40" alt=":)" title="Biggrin" />',
	':\\)': LL.emo + 'more_smilies/biggrin.gif" id="smilie_40" alt=":)" title="Biggrin" />',
	':wink:': LL.emo + 'more_smilies/wink.gif" id="smilie_19" alt=";)" title="Wink" />',
	';\\)': LL.emo + 'more_smilies/wink.gif" id="smilie_19" alt=";)" title="Wink" />',
	':confused1:': LL.emo + 'more_smilies/confused1.gif" alt=":confused1:" title="Confused1" />',
	':laugh:': LL.emo + 'more_smilies/laugh.gif" id="smilie_2" alt=":D" title="Laugh" />',
	':D': LL.emo + 'more_smilies/laugh.gif" id="smilie_22" alt=":D" title="Laugh" />',
	':blink:': LL.emo + 'more_smilies/blink.gif" alt=":blink:" title="Blink" />',
	':innocent:': LL.emo + 'extended/innocent.gif" alt=":innocent:" title="Innocent" />',
	':sleep:': LL.emo + 'more_smilies/sleep.gif" alt=":sleep:" title="Sleep" />',
	':sad:': LL.emo + 'more_smilies/sad.gif" alt=":sad:" title="Sad" />',
	':scared:': LL.emo + 'more_smilies/scared.gif" alt=":scared:" title="Scared" />',
	':blush:': LL.emo + 'more_smilies/blush.gif" alt=":blush:" title="Blush" />',
	':ohmy:': LL.emo + 'more_smilies/ohmy.gif" alt=":ohmy:" title="Ohmy" />',
	':yes:': LL.emo + 'extended/yes.gif" alt=":yes:" title="Yes" />',
	':mellow:': LL.emo + 'more_smilies/mellow.gif" alt=":mellow:" title="Mellow" />',
	':mad:': LL.emo + 'more_smilies/mad.gif" alt=":mad:" title="Mad" />',
	':lol:': LL.emo + 'more_smilies/lol.gif" alt=":lol:" title="Lol" />',
	':tongue_smilie:': LL.emo + 'more_smilies/tongue_smilie.gif" alt=":tongue_smilie:" title="Tongue Smilie" />',
	':sneaky2:': LL.emo + 'more_smilies/sneaky2.gif" alt=":sneaky2:" title="Sneaky2" />',
	':thumbdown:': LL.emo + 'more_smilies/thumbdown.gif" alt=":thumbdown:" title="Thumbdown" />',
	':thumbup1:': LL.emo + 'more_smilies/thumbup1.gif" alt=":thumbup1:" title="Thumbup1" />',
	':thumbup:': LL.emo + 'more_smilies/thumbup.gif" alt=":thumbup:" title="Thumbup" />',
	':angry:': LL.emo + 'more_smilies/angry.gif" alt=":angry:" title="Angry" />',
	':whistling:': LL.emo + 'extended/whistling.gif" alt=":whistling:" title="Whistling" />',
	':w00t:': LL.emo + 'more_smilies/w00t.gif" alt=":w00t:" title="W00t" />',
	':chinese:': LL.emo + 'extended/chinese.gif" alt=":chinese:" title="Chinese" />',
	':chris:': LL.emo + 'extended/chris.gif" alt=":chris:" title="Chris" />',
	':clap:': LL.emo + 'extended/clap.gif" alt=":clap:" title="Clap" />',
	':clover:': LL.emo + 'extended/clover.gif" alt=":clover:" title="Clover" />',
	':clown:': LL.emo + 'extended/clown.gif" alt=":clown:" title="Clown" />',
	':cool2:': LL.emo + 'extended/cool2.gif" alt=":cool2:" title="Cool2" />',
	':cowboy:': LL.emo + 'extended/cowboy.gif" alt=":cowboy:" title="Cowboy" />',
	':detective:': LL.emo + 'extended/detective.gif" alt=":detective:" title="Detective" />',
	':devil:': LL.emo + 'extended/devil.gif" alt=":devil:" title="Devil" />',
	':devil2:': LL.emo + 'extended/devil2.gif" alt=":devil2:" title="Devil2" />',
	':chef:': LL.emo + 'extended/chef.gif" alt=":chef:" title="Chef" />',
	':rolleyes:': LL.emo + 'extended/rolleyes.gif" alt=":rolleyes:" title="Rolleyes" />',
	':drool:': LL.emo + 'more_smilies/drool.gif" alt=":drool:" title="Drool" />',
	':winkiss:': LL.emo + 'extended/winkiss.gif" alt=":winkiss:" title="Winkiss" />',
	':sorcerer:': LL.emo + 'extended/sorcerer.gif" alt=":sorcerer:" title="Sorcerer" />',
	':censored:': LL.emo + 'extended/censored.gif" alt=":censored:" title="Censored" />',
	':red_indian:': LL.emo + 'extended/red_indian.gif" alt=":red_indian:" title="Red Indian" />',
	':cursing:': LL.emo + 'more_smilies/cursing.gif" alt=":cursing:" title="Cursing" />',
	':ninja:': LL.emo + 'extended/ninja.gif" alt=":ninja:" title="Ninja" />',
	':smartass:': LL.emo + 'extended/smartass.gif" alt=":smartass:" title="Smartass" />',
	':boat:': LL.emo + 'extended/boat.gif" alt=":boat:" title="Boat" />',
	':punk:': LL.emo + 'extended/punk.gif" alt=":punk:" title="Punk" />',
	':crying:': LL.emo + 'more_smilies/crying.gif" alt=":crying:" title="Crying" />',
	':kiss:': LL.emo + 'extended/kiss.gif" alt=":kiss:" title="Kiss" />',
	':walkman:': LL.emo + 'extended/walkman.gif" alt=":walkman:" title="Walkman" />',
	':batman:': LL.emo + 'extended/batman.gif" alt=":batman:" title="Batman" />',
	':pinch:': LL.emo + 'extended/pinch.gif" alt=":pinch:" title="Pinch" />',
	':cool:': LL.emo + 'more_smilies/cool.gif" alt=":cool:" title="Cool" />',
	':wacko:': LL.emo + 'extended/wacko.gif" alt=":wacko:" title="Wacko" />',
	':sleep1:': LL.emo + 'extended/sleep1.gif" alt=":sleep1:" title="Sleep1" />',
	':balloon:': LL.emo + 'extended/balloon.gif" alt=":balloon:" title="Balloon" />',
	':phone:': LL.emo + 'extended/phone.gif" alt=":phone:" title="Phone" />',
	':huh:': LL.emo + 'more_smilies/huh.gif" alt=":huh:" title="Huh" />',
	':helpsmilie:': LL.emo + 'extended/helpsmilie.gif" alt=":helpsmilie:" title="Helpsmilie" />',
	':turned:': LL.emo + 'extended/turned.gif" alt=":turned:" title="Turned" />',
	':shifty:': LL.emo + 'extended/shifty.gif" alt=":shifty:" title="Shifty" />',
	':arabia:': LL.emo + 'extended/arabia.gif" alt=":arabia:" title="Arabia" />',
	':ph34r:': LL.emo + 'extended/ph34r.gif" alt=":ph34r:" title="Ph34r" />',
	':glare:': LL.emo + 'more_smilies/glare.gif" alt=":glare:" title="Glare" />',
	':closedeyes:': LL.emo + 'more_smilies/closedeyes.gif" alt=":closedeyes:" title="Closedeyes" />',
	':happybday:': LL.emo + 'extended/happybday.gif" alt=":happybday:" title="Happybday" />',
	':tt2:': LL.emo + 'extended/tt2.gif" alt=":tt2:" title="Tt2" />',
	':rockon:': LL.emo + 'extended/rockon.gif" alt=":rockon:" title="Rockon" />',
	':angel:': LL.emo + 'extended/angel.gif" alt=":angel:" title="Angel" />',
	':oops:': LL.emo + 'extended/oops.gif" alt=":oops:" title="Oops" />',
	':bored:': LL.emo + 'more_smilies/bored.gif" alt=":bored:" title="Bored" />',
	':hammer:': LL.emo + 'extended/hammer.gif" alt=":hammer:" title="Hammer" />',
	':tt1:': LL.emo + 'extended/tt1.gif" alt=":tt1:" title="Tt1" />',
	':shaun:': LL.emo + 'extended/shaun.gif" alt=":shaun:" title="Shaun" />',
	':alien:': LL.emo + 'extended/alien.gif" alt=":alien:" title="Alien" />',
	':online2long:': LL.emo + 'extended/online2long.gif" alt=":online2long:" title="Online2long" />',
	':yinyang:': LL.emo + 'extended/yinyang.gif" alt=":yinyang:" title="Yinyang" />',
	':blushing:': LL.emo + 'more_smilies/blushing.gif" alt=":blushing:" title="Blushing" />',
	':flowers:': LL.emo + 'extended/flowers.gif" alt=":flowers:" title="Flowers" />',
	':tooth:': LL.emo + 'extended/tooth.gif" alt=":tooth:" title="Tooth" />',
	':scooter:': LL.emo + 'extended/scooter.gif" alt=":scooter:" title="Scooter" />',
	':1eye:': LL.emo + 'extended/1eye.gif" alt=":1eye:" title="1eye" />',
	':offtopic:': LL.emo + 'extended/offtopic.gif" alt=":offtopic:" title="Offtopic" />',
	':excl:': LL.emo + 'extended/excl.gif" alt=":excl:" title="Excl" />',
	':sweatdrop:': LL.emo + 'extended/sweatdrop.gif" alt=":sweatdrop:" title="Sweatdrop" />',
	':santa:': LL.emo + 'extended/santa.gif" alt=":santa:" title="Santa" />',
	':001_icon16:': LL.emo + 'extended/001_icon16.gif" alt=":001_icon16:" title="001 Icon16" />',
	':knutschmund:': LL.emo + 'extended/001_icon16.gif" alt=":knutschmund:" title="Knutschmund" />',
	':nuke:': LL.emo + 'extended/nuke.gif" alt=":nuke:" title="Nuke" />',
	':yawn:': LL.emo + 'extended/yawn.gif" alt=":yawn:" title="Yawn" />',
	':euro:': LL.emo + 'extended/euro.gif" alt=":euro:" title="Euro" />',
	':surrender:': LL.emo + 'extended/surrender.gif" alt=":surrender:" title="Surrender" />',
	':sailor:': LL.emo + 'extended/sailor.gif" alt=":sailor:" title="Sailor" />',
	':nono:': LL.emo + 'extended/nono.gif" alt=":nono:" title="Nono" />',
	':wub:': LL.emo + 'extended/wub.gif" alt=":wub:" title="Wub" />',
	':eek:': LL.emo + 'extended/eek.gif" alt=":eek:" title="Eek" />',
	':stuart:': LL.emo + 'extended/stuart.gif" alt=":stuart:" title="Stuart" />'
};*/

LL.substituteSmilieShortcuts = function (originalHtml) {
	/*if (typeof(originalHtml) === 'string') {
		for (var shortcut in LL.smilieReplacements) {
			originalHtml = originalHtml.replace(new RegExp('([^"])' + shortcut + '([^"])'), "$1" + LL.smilieReplacements[shortcut] + "$2");
		}
		return originalHtml;
	}*/
	return originalHtml;
};

LL.removeTinyMce = function(editorId) {
	if (!tinyMCEoff) {
		$(editorId).tinymce().remove();
		/*var $container = $(editorId);
		var $editor    = $container.tinymce();
		if ($editor) {
			$editor.remove();
		}*/
	}
};

LL.initTinyMce = function(editorId, config) {
	if (!tinyMCEoff) {
		$(editorId).tinymce(config);
	}
};

// Facebook ----------------------------------------------------------------------------------------

LL.initFacebookLoginDone = false;
LL.initFacebookLogin = function() {
	if (LL.defaults.facebookLoginEnabled && !LL.initFacebookLoginDone) {
		$.getScript(
			'https://connect.facebook.net/de_DE/all.js',
			function () {
				try {
					FB.init({
						"appId": LL.defaults.facebookAppId,
						"cookie": true,
						"oauth": true,
						"channelUrl": "/js/fb/channel.html",
						"status": true,
						"xfbml": true
					});
				} catch (e) {
					log("ERROR: FB initialisation in initFacebookLogin()", e);
				}
			}
		);

		LL.initFacebookLoginDone = true;
	}
};

// Mobile ------------------------------------------------------------------------------------------

function getLayoutMode() {
	var device = readCookie("device");

	if (device === null) {
		return "auto";
	} else if (device === "desktop") {
		return "desktop";
	} else {
		return "mobile";
	}
}

function setLayoutMode(mode) {
	if (mode === "auto") {
		eraseCookie("device");
	} else if (mode === "desktop") {
		createCookie("device", "desktop");
	} else if (mode === "mobile") {
		createCookie("device", "mobile");
	}
}

function switchToLayoutMode(mode) {
	setLayoutMode(mode);
	window.location.reload();
}

function switchToDesktopMode() {
	switchToLayoutMode("desktop");
}

function switchToMobileMode() {
	switchToLayoutMode("mobile");
}

function isMobileLayout() {
	return LL.defaults.isMobile;
}

function isLoggedIn() {
	return LL.defaults.isLoggedIn;
}

// Init after page load ----------------------------------------------------------------------------

$(function() {

	// Toggle login status dependent content -----------------------------------

	if (isLoggedIn()) {
		$(".logged-out-only").addClass("versteckt");
		$(".logged-in-only").removeClass("versteckt");
	}

	// Lazy loading of images ---------------------------------------------------

	$("img.lazy").lazyload({
		threshold: 300,
		failure_limit: 9999999
	});

	// Datepicker ---------------------------------------------------------------

	$(".datepicker").llDatepicker();

	// Set mobile social bar threshold depending on article length --------------

	(function() {
		var $footer = $("#footer"), threshold;
		if ($footer.length > 0) {
			threshold = $footer.position().top - $(window).height();
			$(".socialbar").attr("data-mobile-scroll-threshold", threshold);
		}
	})();
	$(".socialbar").socialbar();

	// Sticky header ---------------------------------------

	$("#masthead, #dl-menu").each(function () {
		var $window = $(window),
			$document = $(document),
			$header = $(this),
			hideClass = "site-header--hidden dl-menuwrapper--hidden",
			lastScroll = $document.scrollTop(),
			scrollDelta = 0,
			scrollDeltaSum = 0,
			pageScrollTop = 0,
			pauseScrollDetection = false,
			pauseTimeout;

		// Wait until content is loaded and browser scroll events were fired
		$window.on('load', function () {
			if ($header.outerHeight(true) < $document.scrollTop()) {
				$header.addClass(hideClass);
			}

			$(document).on('click', 'a', function() {
				$header.trigger('pause-scroll-detection');

				if ($header.outerHeight(true) < $document.scrollTop()) {
					$header.addClass(hideClass);
				}
			});

			$header.on('pause-scroll-detection', function(e, pauseDurationInMs) {
				window.clearTimeout(pauseTimeout);
				pauseScrollDetection = true;

				pauseTimeout = window.setTimeout(function() {
					pauseScrollDetection = false;
				}, pauseDurationInMs || 50);
			});

			$window.on('scroll', function () {
				if (pauseScrollDetection) {
					return;
				}

				pageScrollTop = $document.scrollTop();

				if (pageScrollTop > $header.outerHeight(true)) {
					if (scrollDeltaSum < -100) {
						$header.removeClass(hideClass);

					}
					else if (scrollDeltaSum > 100) {
						$header.addClass(hideClass);
					}

					// Sum up scroll deltas and reset sum on scroll direction change
					scrollDelta = pageScrollTop - lastScroll;

					if ((scrollDelta < 0 && scrollDeltaSum > 0) || (scrollDelta > 0 && scrollDeltaSum < 0)) {
						scrollDeltaSum = 0;
					}

					scrollDeltaSum += scrollDelta;
					lastScroll = pageScrollTop;
				}
				else {
					$header.removeClass(hideClass);
				}
			});

		});
	});

	// Sticky skyscaper ads---------------------------------

	// Classical layout
	(function () {
		var $content = $(".page_inside");
		var $banners = $(".banner_left--sticky, .banner_right--sticky");

		function refreshBannerStickyClass() {
			var isSticky = $content[0].getBoundingClientRect().top < 0;
			$banners.toggleClass('fixed', isSticky);
		}

		if ($content.length && $banners.length) {
			$(window).on('scroll', refreshBannerStickyClass);
			refreshBannerStickyClass();
		}
	})();

	// Modern layout
	(function () {
		var $header = $(".top-header");
		var $banners = $(".banner_left--sticky, .banner_right--sticky");

		function refreshBannerStickyClass() {
			var isSticky = $header[0].getBoundingClientRect().top < $header.height() * -1;
			$banners.toggleClass('fixed', isSticky);
		}

		if ($header.length && $banners.length) {
			$(window).on('scroll', refreshBannerStickyClass);
			refreshBannerStickyClass();
		}
	})();

	// Galerie-Swiper --------------------------------------

	// New galleries - auto initialisation
	$(".mod_gallery").each(function () {
		var $gallery = $(this),
			$slick = $(".slick", $gallery),
			slick = null,
			headline = $("h1").text();

		$slick.slick({
			adaptiveHeight: true,
			fade: false,
			lazyLoad: "ondemand",
			nextArrow: "<div class='next-wrap'><div class='next' href='#' role='button' title='weiter'></div></div>",
			prevArrow: "<div class='prev-wrap'><div class='prev' href='#' role='button' title='zurück'></div></div>"
		});

		function refreshSidebar() {
			var currentSlideIndex = $slick.slick("slickCurrentSlide"),
				$currentSlide = $(slick.$slides[currentSlideIndex]),
				headline = $currentSlide.find(".hl").html(),
				caption = $currentSlide.find(".txt").html();

			$(".navibar .current-page", $gallery).text(currentSlideIndex + 1);

			if ($currentSlide.hasClass("inner")) {
				$(".navibar .current-caption", $gallery).html(caption);
				$(".navibar .current-headline", $gallery).html(headline);
			} else {
				$(".navibar .current-caption", $gallery).html("");
				$(".navibar .current-headline", $gallery).html("");
			}
		}

		function loadLazyImages() {
			var currentIndex = $slick.slick("slickCurrentSlide"),
				slideCount = slick.$slides.length,
				allSlides = Array.from(slick.$slides),
				loadSlides = [],
				$img;

			if (slideCount < 3) {
				return;
			}

			// Get next slides
			loadSlides = loadSlides.concat(allSlides.slice(currentIndex, currentIndex + 3));
			if (currentIndex + 3 > slideCount) {
				loadSlides = loadSlides.concat(allSlides.slice(3));
			}

			// Get previous slides
			loadSlides = loadSlides.concat(allSlides.slice(currentIndex - 3, currentIndex));
			if (currentIndex < 3) {
				loadSlides = loadSlides.concat(allSlides.slice(-3))
			}

			// Load lazy images in slides
			$.each(loadSlides, function(key, slide) {
				$(slide).find("[data-lazy]").each(function () {
					$img = $(this);
					$img.attr("src", $img.data("lazy")).removeAttr("data-lazy");
				});

				$(slide).find(".lazy[data-original]").each(function () {
					$img = $(this);
					$img.attr("src", $img.data("original")).removeAttr("data-original");
				});
			});
		}

		function triggerIvw(directionName, triggerName) {
			if (directionName === 'prev') {
				LL.actions.klick_fotostrecke_zurueck({
					"headline": headline,
					"trigger": triggerName
				});
			} else {
				LL.actions.klick_fotostrecke_vor({
					"headline": headline,
					"trigger": triggerName
				});
			}
		}

		function goToPageViaUrl() {
			if (window.location.hash) {
				var matches = window.location.hash.match(/#bild([0-9]+)/);
				if (matches) {
					var slideIndex = matches[1] - 1;
					$slick.slick("slickGoTo", slideIndex);
				}
			}
		}

		function refreshUrlHash() {
			var currentIndex = $slick.slick("slickCurrentSlide") + 1;

			location.hash = 'bild' + currentIndex;
		}

		/**
		 * Fix the slides list height in combination of custom lazy
		 * loading, automatic height resizing and switching the gallery
		 * slides too fast (before the images are lazy-loaded).
		 */
		function fixAutoResizeHeight() {
			const minSlideHeight = 30;
			var $slidesList = $(".slick-list", $gallery);
			if ($slidesList.height() < minSlideHeight) {
				var interval = window.setInterval(function () {
					slick.setHeight();
					if ($slidesList.height() > minSlideHeight) {
						clearInterval(interval);
					}
				}, 100);
			}
		}

		function refreshLastSlideClass() {
			if ($slick.slick("slickCurrentSlide") + 1 === slick.$slides.length) {
				$gallery.addClass("last-slide-active");
			} else {
				$gallery.removeClass("last-slide-active");
			}
		}

		function initSwipeEvents() {
			$slick.on('swipe', function(event, slick, direction) {
				if (direction === 'left') {
					triggerIvw("next", "swipe");
				} else {
					triggerIvw("prev", "swipe");
				}
			});
		}

		function initButtonEvents() {
			$(".prev", $gallery).click(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$slick.slick("slickPrev");
				triggerIvw("prev", "pfeil_button");
			});
			$(".next", $gallery).click(function (e) {
				e.preventDefault();
				e.stopPropagation();
				$slick.slick("slickNext");
				triggerIvw("next", "pfeil_button");
			});
		}

		function initKeyboardEvents() {
			var prev = $(".prev", $gallery)[0],
				next = $(".next", $gallery)[0];

			$(document).keyup(function(e) {
				switch (e.keyCode) {
					// Cursor left
					case 37:
						triggerIvw("prev", "keypress");
						$slick.slick("slickPrev");
						break;
					// Cursor right
					case 39:
						triggerIvw("next", "keypress");
						$slick.slick("slickNext");
						break;
				}
			});
		}

		function initNotificationEvents() {
			$slick.on('beforeChange', function(){
				$("#masthead, #dl-menu").trigger("pause-scroll-detection", 3000);
			});
		}

		function refreshInlineSparkAd() {
			const $activeAdSlide = $gallery.find(".slick-slide.slick-current.isAd");
			const $galleryAd = $gallery.find(".gallery-inline-ad-spark");

			if ($activeAdSlide.length && $galleryAd.length) {
				$activeAdSlide.find(".wrapper").append($galleryAd);
				const refreshAdEvent = new CustomEvent("refresh-gallery-spark-ad", {
					detail: {
						slot: $galleryAd.attr("id")
					}
				});
				document.dispatchEvent(refreshAdEvent);
			}
		}

		function init() {
			slick = $slick.slick("getSlick");
			refreshSidebar();
			initKeyboardEvents();
			initButtonEvents();
			initSwipeEvents();
			initNotificationEvents();
			goToPageViaUrl();
			loadLazyImages();
			refreshLastSlideClass();

			$slick.on("afterChange", function () {
				refreshSidebar();
				loadLazyImages();
				refreshUrlHash();
				refreshLastSlideClass();
				refreshInlineSparkAd();
				fixAutoResizeHeight();
			});
		}

		init();
	});

	// 5 Star voting / rating --------------------------------------

	$(".raty-wrapper").each(function () {
		var $raty = $(".raty", this),
			$message = $(".raty-message", this),
			ratyId = $raty.data("id"),
			isTemporarilyDisabled = false,
			lockTimeMs = 3000;

		function init() {
			$raty.raty({
				click: function(score) {
					if (!isTemporarilyDisabled) {
						rate(score);
					}
				},
				cancelButton: false,
				half: false,
				halfShow: true,
				hints: ["", "", "", "", ""],
				noRatedMsg: "",
				starHalf: "star-half",
				starOff: "star-empty",
				starOn: "star",
				starType: "i"
			});

			// Disable raty if no local sorage is present to disallow voting multiple times
			if (typeof window.localStorage !== "object") {
				$raty.raty("readOnly", true);
			}

			requestInitialRating();
		}

		function getScore() {
			return localStorage.getItem('raty-' + ratyId);
		}

		function setScore(score) {
			localStorage.setItem('raty-' + ratyId, score);
		}

		function setIsDisabled(status) {
			$raty.raty("readOnly", status);
			isTemporarilyDisabled = status;
		}

		function showMessage(resultList) {
			var message,
				average = getAverage(resultList),
				averageFormatted = average.replace(".", ","),
				voteCount = getVoteCount(resultList),
				myScore = getScore();

			if (myScore > 0) {
				// Durch Caching kann hier der eigene Wert fehlen, was zumindest bei sonst 0 Votings
				// dann manuell "eingerechnet" wird:
				if (voteCount == 0) {
					averageFormatted = myScore;
					voteCount = 1;
				}

				message = "<strong>Ihre Bewertung: " + myScore + "</strong>"
					+ " | &empty; " + averageFormatted + " / 5 | Bewertungen: " + voteCount;
			} else if (voteCount > 0) {
				message = "<strong>&empty; " + averageFormatted + " / 5</strong> | Bewertungen: " + voteCount;
			} else {
				message = "Jetzt bewerten";
			}

			$message.html(message);
		}

		function requestResultList(callback) {
			$.ajax({
				type: "GET",
				url: "/rate/" + ratyId
			}).done(function(resultList) {
				callback(resultList);
			});
		}

		function submitRate(score, callback) {
			$.ajax({
				type: "POST",
				url: "/rate/" + ratyId + "/" + score
			}).done(function(resultList) {
				setScore(score);
				callback(resultList);
			});
		}

		function submitUnRate(score, callback) {
			$.ajax({
				type: "POST",
				url: "/unrate/" + ratyId + "/" + score
			}).done(function(resultList) {
				callback(resultList);
			});
		}

		function getAverage(resultList) {
			var average = 0;

			if (typeof resultList === "object") {
				var votes = 0,
					sum = 0;

				$.each(resultList, function(stars, count) {
					votes += count;
					sum += stars * count;
				});

				if (votes > 0) {
					average = (sum / votes).toFixed(1);
				} else {
					average = "-";
				}
			}

			return average;
		}

		function getVoteCount(resultList) {
			var count = 0;

			if (typeof resultList === "object") {
				$.each(resultList, function(stars, voters) {
					count += voters;
				});
			}

			return count;
		}

		function requestInitialRating() {
			requestResultList(function (resultList) {
				$raty.raty("score", getAverage(resultList));
				showMessage(resultList);
			});
		}

		function rate(newScore) {
			if (getScore()) {
				setIsDisabled(true);
				submitUnRate(getScore(), function () {
					submitRate(newScore, function (resultList) {
						setIsDisabled(false);
						$raty.raty("score", getAverage(resultList));
						showMessage(resultList);
					});
				});
			} else {
				setIsDisabled(true);
				submitRate(newScore, function (resultList) {
					setIsDisabled(false);
					$raty.raty("score", getAverage(resultList));
					showMessage(resultList);
				});
			}
		}

		init();
	});

	// Expertise slider --------------------------------------

	$(".mod_expertise").each(function () {
		var $gallery = $(this),
			$slick = $(".mod_expertise__slider", $gallery),
			headline = $('.intro .hd', this).text(),
			slick;

		$slick.slick({
			adaptiveHeight: true,
			fade: false,
			dots: true,
			lazyLoad: "ondemand",
			nextArrow: "<div class='next-wrap'><div class='next' href='#' role='button' title='weiter'></div></div>",
			prevArrow: "<div class='prev-wrap'><div class='prev' href='#' role='button' title='zurück'></div></div>"
		});

		function setPrevButtonVisible(visible) {
			$slick.find(".prev-wrap").toggle(visible);
		}

		function triggerIvw(isBackwards) {
			if (isBackwards) {
				LL.actions.klick_expertise_zurueck({
					"headline": headline,
					"trigger": "back"
				});
			} else {
				LL.actions.klick_expertise_vor({
					"headline": headline,
					"trigger": "forward"
				});
			}
		}

		function init() {
			slick = $slick.slick("getSlick");
			setPrevButtonVisible(false);

			$slick.on("afterChange", function (e, slider, direction) {
				var isFirstSlide = $slick.slick("slickCurrentSlide") === 0,
					isBackwards = direction === 0;

				setPrevButtonVisible(!isFirstSlide);
				triggerIvw(isBackwards);
			});
		}

		init();
	});

	// Mobile Sticky Banner Ad --------------------------------------

	$(".ad_bottom_close_button").on("click", function () {
		$(this).parent().css("display", "none");
	});

});
